.menu-item {
  font-size: 1rem;

  .input-checkbox {
    --input-checkbox-padding: 0;

    &::before {
      content: ""; // Make checkbox label centered
    }
  }

  &.selected {
    --selected-color: var(--blue);
    cursor: default !important;
    background-color: transparent !important;
    color: var(--selected-color) !important;

    label {
      cursor: default !important;
      color: var(--selected-color) !important;
    }
  }

  &.selected:hover,
  &.selected.hover {
    background-color: transparent !important;
    color: var(--selected-color) !important;

    label {
      color: var(--selected-color) !important;
    }
  }

  &.disabled {
    --disabled-color: var(--color3);
    cursor: default !important;
    background-color: transparent !important;
    color: var(--disabled-color) !important;

    label {
      cursor: default !important;
      color: var(--disabled-color) !important;
    }
  }

  &.disabled:hover,
  &.disabled.hover {
    background-color: transparent !important;
    color: var(--disabled-color) !important;

    label {
      color: var(--disabled-color) !important;
    }
  }
}