.histogram-container {
  position: relative;
  // height is javascript calculated
  transition: height var(--element-transition-duration) ease 0s;

  .histogram-info {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    width: max-content;

    text-align: center;
    display: grid;
    gap: 0.5rem;

    .histogram-total {
      font-size: 1em;
    }

    .histogram-average {
      font-size: 0.8em;
      transition: opacity var(--element-transition-duration) ease 0s;

      &.show {
        opacity: 1;
      }

      &.hide {
        opacity: 0;
      }
    }
  }

  .scroll-container {
    position: relative;
    z-index: 1;
    overflow-x: scroll;
    /**
     * Do not scroll vertivally, even if there's some extra pixels.
     * Botth height of scroll container and histogram graph are 200px,
     * bbut histogram is actually 204px height. Why ?
     * That induces a vertical scroll that we do not want.
     * So... overflow-y: hidden;
     */
    overflow-y: hidden;
    overscroll-behavior-x: none;
  }

  .date-lines {
    display: flex;
    justify-content: space-between;
    margin-top: calc(-1 * var(--date-line-height));
    transition: opacity var(--element-transition-duration) ease 0s;
  }

  .date-lines.visible {
    opacity: 1;
  }
  .date-lines.hidden {
    opacity: 0;
  }

  .dates {
    font-size: var(--date-font-size);
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    width: 100%;
    .date {
      width: 33.33%;
    }
    .left {
      text-align: left;
    }
    .center {
      text-align: center;
    }
    .right {
      text-align: right;
    }

    &.visible {
      height: var(--date-font-size);
      // margin-top: 0.5rem;
    }

    &.hidden {
      height: 0;
      margin-top: 0;
    }

    transition: margin-top var(--element-transition-duration) ease 0s,
    height var(--element-transition-duration) ease 0s;
  }
}