.document {
  --document-text-color: var(--text-color, var(--color5, #565763));
  --document-border-radius: var(--border-radius, 10px);
  --document-padding : calc(var(--content-padding) / 2);
  --document-margin : calc(var(--content-padding) / 2);
  --document-background-color: rgba(0, 0, 0, 0.2);
  &.light  {
    --document-background-color: rgba(255, 255, 255, 0.2);
  }

  // POSITION
  position: relative;

  // SHAPE
  border-radius: var(--document-border-radius);
  margin: var(--document-margin);
  padding: var(--document-padding);
  padding-top: calc(var(--button-height) + var(--content-padding) / 3);
  padding-bottom: calc(var(--button-height) + var(--content-padding) / 3);
  box-sizing: border-box;
  // height: 100%; // MUST be Javascript calculated to set a fixed pixel number for transition purpose
  
  // APPEARANCE
  color: var(--document-text-color);
  background: var(--document-background-color);
  border: var(--layout-border-width, 0px) solid var(--document-text-color);
  opacity: 1;

  // CONTENT
  overflow: hidden;
  /* Define font-size to be able to change locally with --font-size variable */
  font-size: 1em;
  h1 {
    /* Override user-agent style in sections */
    /* Using em to get font-size proportionnal to parent font-size */
    font-size: 2em;
  }

  article {
    overflow: scroll;
    height: calc(100% + (var(--border-with) - 1px) * 2);
    /*
     * 100% still has some pixels of scroll overflow
     * I've come up with  the formula (border-width - 1) * 2 empiricaly after few tests
     *        border-with | 0 1 2 3 4 5 6  7  8
     *        overflow    | 0 0 2 4 6 8 10 12 14
     * ~> follows the rule: (border-width - 1) * 2, except for 0, but no overflow occurs anyway
     * I add this quantity to the article height so its content to fill the space without overflowing.
     */
  }

  transition: var(--element-transition-duration, 2s);
  &.is-closed {
    transition: var(--element-transition-duration, 2s);
    // height: 0; // Set by Javascript
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    border-width: 0;
  }

  .top-buttons {
    // POSITION
    position: absolute;
    right: calc(var(--content-padding) * 1 / 6);
    top: calc(var(--content-padding) * 1 / 6);

    // CONTENT
    display: flex;
    gap: 0.5rem;
  }
  
  .close-button {
    position: absolute;
    right: calc(var(--document-padding) / 3);
    bottom: calc(var(--document-padding) / 3);
  }
}

.section .document {
  /* Do not add margin to documents inside sections */
  margin: 0;
  /* Apply section attributes */
  --document-text-color: var(--section-text-color);
}
