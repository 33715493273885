:root {
  /* GENERAL NAVIGATION  */
  --nav-item-height: 3rem;
  --nav-item-gap: 0.3rem;
  --nav-item-x-padding: 1rem;
  --nav-item-border-width: var(--border-width);
  --nav-item-underline-width: 100%;

  --nav-hover-background-color: var(--color5);
  --nav-active-background-color: var(--primary);
  --nav-underline-thickness: 2px;
  --nav-underline-height: 0.3rem;

  /* LAYOUT */
  --layout-border-width: var(--border-width);
  --layout-background-color: var(--color1);
  --layout-background-color--rgb: var(--color1--rgb);
  --layout-transition-duration: 0.3s;
  --element-transition-duration: 0.3s;

  /* CONTENT */
  --content-background-color: var(--color2);
  --content-background-color--rgb: var(--color2--rgb);
  --content-border-color: none;
  --content-padding: 3rem;
  --content-padding-phablet: calc(var(--content-padding) * 2 / 3);
  --content-padding-phone: calc(var(--content-padding) / 3);
  --content-transition-duration: 0.5s;
  --image-transition-duration: 2s;

  /* MAIN */
  --main-border-width: var(--layout-border-width);
  --main-border-color: var(--border-color);

  /* SIDENAVBAR */
  --sidenavbar-display: block;
  --sidenavbar-position: absolute;
  --sidenavbar-align-items: baseline;
}

html {
  background: var(--layout-background-color);
}

#app {
  /* GENERAL NAVIGATION  */
  --local-nav-item-height: var(--nav-item-height, 3rem);
  --local-nav-item-gap: var(--nav-item-gap, 0.3rem);
  --local-nav-item-x-padding: var(--nav-item-x-padding, 1rem);
  --local-nav-item-border-width: var(--nav-item-border-width, 1px);
  --local-nav-item-underline-width: var(--nav-item-underline-width, 100%);

  --local-nav-hover-background-color: var(--nav-hover-background-color, var(--color5, #565763));
  --local-nav-active-background-color: var(--nav-active-background-color, var(--primary, rgb(13, 24, 34)));
  --local-nav-underline-thickness: var(--nav-underline-thickness, 2px);
  --local-nav-underline-height: var(--nav-underline-height, 0.3rem);

  /* LAYOUT */
  --local-layout-background-color: var(--layout-background-color, var(--color1, #ffffff));
  --local-layout-transition-duration: var(--layout-transition-duration, 0.3s);
  --local-element-transition-duration: var(--element-transition-duration, 0.3s);

  /* CONTENT */
  --local-content-background-color: var(--content-background-color, var(--color1, #ffffff));
  --local-content-border-color: var(--content-border-color, none);
  --local-content-padding: var(--content-padding, 3rem);
  --local-content-padding-phablet: var(--content-padding-phablet, calc(var(--content-padding) * 2 / 3));
  --local-content-padding-phone: var(--content-padding-phone, calc(var(--content-padding) / 3));
  --local-content-transition-duration: var(--content-transition-duration, 0.5s);
  --local-image-transition-duration: var(--image-transition-duration, 2s);

  /* MAIN */
  --local-main-border-width: var(--main-border-width);
  --local-main-border-color: var(--main-border-color);

  /* SIDENAVBAR */
  --local-sidenavbar-display: var(--sidenavbar-display, block);
  --local-sidenavbar-position: var(--sidenavbar-position, absolute);
  --local-sidenavbar-align-items: var(--sidenavbar-align-items, baseline);

  // SHAPE
  width: 100%;
  height: 100%;

  // POSITION
  position: absolute;
  top: 0;
  margin-left: auto;
  margin-right: auto;

  // APPEARANCE
  display: var(--local-sidenavbar-display);
  background-color: var(--local-layout-background-color);

  display: flex;
  flex-direction: column-reverse;

  @media only screen and (min-width: 481px) {
    flex-direction: column;
  }

  /////////////////////////
  // BLOCKS DEFINITION //
  /////////////////////
  >.navbar {
    // POSITION
    position: -webkit-sticky;
    position: var(--navbar-position);
    top: 0;
    z-index: 300;

    /* 
     * Display hamburger as sidenavbar
     * Used when hiding sidenavbar with display: none
     */
    #hamburger {
      display: var(--sidenavbar-display);
    }
  }

  >.content {
    // POSITION
    position: relative;

    // SHAPE
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: calc(100% - var(--navbar-height));

    // CONTENT
    // Makes sidenavbar and main-container side by side
    display: flex;

    #sidenavbar {
      // POSITION
      position: var(--local-sidenavbar-position);

      @media only screen and (min-width: 1201px) {
        position: relative; // On the side (vs. "absolute" where it's over content)
      }

      top: 0;
      left: 0;
      z-index: 200;

      .sidebar-items {
        align-items: var(--local-sidenavbar-align-items);
        justify-content: flex-end; // For mobiles

        @media only screen and (min-width: 481px) {
          justify-content: flex-start; // For tablets and above
        }
      }
    }

    >.main-container {
      // POSITION
      position: relative;
      z-index: 100;

      // SHAPE
      width: 100%;
      height: 100%;
      // APPEARANCE
      background-color: var(--local-content-background-color);

      // CONTENT
      // Sticky position (footer) DO NOT WORK with hidden overflow, so we use it only on x-axis
      overflow-x: hidden;
      scroll-snap-type: y proximity;

      >main {
        // SHAPE
        min-height: 100%;

        /* Makes content moves in a block when pushed by navsidebar on small screens */
        @media screen and (max-width: 480px) {
          min-width: 100vw;
        }

        // POSITION
        position: relative;
        top: 0;
        z-index: 200;
        padding: 0.1px; // Workaround, see below
        /**
         * Weirdly, main offsetTop has a negative value instead of 0.
         * And even more weird, setting non-nul a padding fix the issue with offsetTop and set it to 0.
         * As I do not want padding, 0.1px does the job without actually seeing a padding on the element.
         */

        // APPEARANCE
        background-color: var(--local-content-background-color);
        box-shadow: 0 1px 1px 1px var(--shadow-color);
        /* Set fadein effect when the component is mounted */
        animation: var(--local-content-transition-duration) ease-out 0s 1 fadeIn;
        -webkit-animation: var(--local-content-transition-duration) ease-out 0s 1 fadeIn;

        @keyframes fadeIn {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }

        // CONTENT
        /* Define font-size to be able to change it locally with --local-font-size variable */
        font-size: 1em;
      }

      >footer {
        // POSITION
        /* Works only for screens > 480px because hidden overflow is needed for small screens */
        position: sticky;
        position: -webkit-sticky;
        bottom: 0;
        z-index: 100;

        @media only screen and (max-width: 480px) {
          /* Hide footer on mobiles, Navbar is at the bottom */
          display: none;
          /* Makes content moves in a block when pushed by navsidebar on small screens */
          // min-width: 100vw;
        }
      }
    }
  }

  /////////////////////////////
  // MAIN ELEMENTS BORDERS //
  /////////////////////////
  main {
    box-sizing: border-box;
    border: none;
    @media only screen and (min-width: 481px) {
      border-bottom: var(--local-main-border-width) solid var(--local-main-border-color);
    }

    /* Set borders to direct children of the main content */
    >section,
    >article {
      border: none;
      @media only screen and (min-width: 481px) {
        /* Set borders of the first section */
        &:first-of-type {
          border-top: 1px solid var(--local-content-border-color);
          border-right: 1px solid var(--local-content-border-color);
          border-bottom: none;
          border-left: 1px solid var(--local-content-border-color);
        }
        /* Set borders of the last section */
        &:last-of-type {
          border-top: none;
          border-right: 1px solid var(--local-content-border-color);
          border-bottom: 1px solid var(--local-content-border-color);
          border-left: 1px solid var(--local-content-border-color);
        }
        /* Set borders of middle section */
        &:not(:first-of-type):not(:last-of-type) {
          border-top: none;
          border-right: 1px solid var(--local-content-border-color);
          border-bottom: none;
          border-left: 1px solid var(--local-content-border-color);
        }
        /* Set borders of only section */
        &:first-of-type:last-of-type {
          border-top: 1px solid var(--local-content-border-color);
          border-right: 1px solid var(--local-content-border-color);
          border-bottom: 1px solid var(--local-content-border-color);
          border-left: 1px solid var(--local-content-border-color);
        }
      }
    }
  }
}