@import './underline.scss';
:root {
  // --sidenavbar-display: block; // Set by layout
  // --sidenavbar-position: absolute; // Set by layout
  --sidenavbar-width: 100%;
  --sidenavbar-width-for-wide-screen: 10rem;
  --sidenavbar-height: 100%;
  --sidenavbar-y-padding: 1rem;
  --sidenavbar-background-color: var(--layout-background-color);
  --sidenavbar-backdrop-filter: var(--backdrop-filter);
  --sidenavbar-transition-duration: var(--layout-transition-duration);

  --sidenavbar-item-height: var(--nav-item-height);
  --sidenavbar-item-width: fit-content;
  --sidenavbar-item-gap: var(--nav-item-gap);
  --sidenavbar-item-x-padding: var(--nav-item-x-padding);
  --sidenavbar-item-border-width: var(--nav-item-border-width);
  --sidenavbar-item-underline-width: var(--nav-item-underline-width);

  --sidenavbar-item-text-color: var(--text-color);
  --sidenavbar-item-underline-color: var(--sidenavbar-background-color);
  --sidenavbar-item-background-color: var(--sidenavbar-background-color);
  --sidenavbar-item-border-color: var(--sidenavbar-item-text-color);

  --sidenavbar-hover-text-color: var(--sidenavbar-background-color);
  --sidenavbar-hover-underline-color: var(--sidenavbar-hover-text-color);
  --sidenavbar-hover-background-color: var(--nav-hover-background-color);
  --sidenavbar-hover-border-color: var(--sidenavbar-hover-background-color);

  --sidenavbar-active-text-color: var(--sidenavbar-background-color);
  --sidenavbar-active-underline-color: var(--sidenavbar-active-text-color);
  --sidenavbar-active-background-color: var(--nav-active-background-color);
  --sidenavbar-active-border-color: var(--sidenavbar-active-background-color);
}

#sidenavbar {
  // --local-sidenavbar-display: var(--sidenavbar-display, block); // Set by layout
  // --local-sidenavbar-position: var(--sidenavbar-position, absolute); // Set by layout
  --local-sidenavbar-width: var(--sidenavbar-width, 100%);
  --local-sidenavbar-width-for-wide-screen: var(--sidenavbar-width-for-wide-screen, 10rem);
  --local-sidenavbar-height: var(--sidenavbar-height, max-content);
  --local-sidenavbar-y-padding: var(--sidenavbar-y-padding, 1rem);
  --local-sidenavbar-background-color: var(--sidenavbar-background-color, var(--layout-background-color, var(--color1, #ffffff)));
  --local-sidenavbar-backdrop-filter: var(--sidenavbar-backdrop-filter, var(--backdrop-filter));
  --local-sidenavbar-transition-duration: var(--sidenavbar-transition-duration, var(--layout-transition-duration, .2s));

  --local-sidenavbar-item-height: var(--sidenavbar-item-height, var(--nav-item-height, 3rem));
  --local-sidenavbar-item-width: var(--sidenavbar-item-width, fit-content);
  --local-sidenavbar-item-gap: var(--sidenavbar-item-gap, var(--nav-item-gap, .3rem));
  --local-sidenavbar-item-x-padding: var(--sidenavbar-item-x-padding, var(--nav-item-x-padding, 1rem));
  --local-sidenavbar-item-border-width: var(--sidenavbar-item-border-width, var(--nav-item-border-width, var(--border-width, 1px)));
  --local-sidenavbar-item-underline-width: var(--sidenavbar-item-underline-width, var(--nav-item-underline-width, 100%));

  --local-sidenavbar-item-text-color: var(--sidenavbar-item-text-color, var(--text-color, #565763));
  --local-sidenavbar-item-underline-color: var(--sidenavbar-item-underline-color, var(--local-sidenavbar-background-color));
  --local-sidenavbar-item-background-color: var(--sidenavbar-item-background-color, var(--local-sidenavbar-background-color));
  --local-sidenavbar-item-border-color: var(--sidenavbar-item-border-color, var(--local-sidenavbar-item-text-color));

  --local-sidenavbar-hover-text-color: var(--sidenavbar-hover-text-color, var(--local-sidenavbar-background-color));
  --local-sidenavbar-hover-underline-color: var(--sidenavbar-hover-underline-color, var(--local-sidenavbar-hover-text-color));
  --local-sidenavbar-hover-background-color: var(--sidenavbar-hover-background-color, var(--nav-hover-background-color, var(--color5, #565763)));
  --local-sidenavbar-hover-border-color: var(--sidenavbar-hover-border-color, var(--local-sidenavbar-hover-background-color));

  --local-sidenavbar-active-text-color: var(--sidenavbar-active-text-color, var(--local-sidenavbar-background-color));
  --local-sidenavbar-active-underline-color: var(--sidenavbar-active-underline-color, var(--local-sidenavbar-active-text-color));
  --local-sidenavbar-active-background-color: var(--sidenavbar-active-background-color, var(--nav-active-background-color, var(--primary, rgb(13, 24, 34))));
  --local-sidenavbar-active-border-color: var(--sidenavbar-active-border-color, var(--local-sidenavbar-active-background-color));

  // POSITION
  // position: var(--local-sidenavbar-position); // Set by layout 
  
  // APPEARANCE
  // display: var(--local-sidenavbar-display); // Set by layout: "block" or "none" to hide it
  background: var(--local-sidenavbar-background-color);
  /*
   * We need to apply backdrop-filter when opened for the effect to work
   */
  &.is-open {
    backdrop-filter: var(--local-sidenavbar-backdrop-filter);
    width: var(--local-sidenavbar-width);
    min-width: var(--local-sidenavbar-width);
  }

  // SHAPE
  box-sizing: border-box;
  padding-top: var(--local-sidenavbar-y-padding);
  padding-bottom: var(--local-sidenavbar-y-padding);
  height: var(--local-sidenavbar-height);
  /* Always visible on big screens */
  @media screen and (min-width: 1200px) {
    min-width: var(--local-sidenavbar-width-for-wide-screen);
    width: var(--local-sidenavbar-width-for-wide-screen);
  }

  // CONTENT
  /* Scroll to access menu items if too many */
  // overflow: scroll;

  // NAVIGATION ITEMS
  /*
   * Wrapper allow us to use width: 100%
   * to fill sidenavbar width correctly without taking into account padding borders etc
   */
  .item-wrapper {
    width: var(--local-sidenavbar-item-width);
    
    a {
      // APPEARANCE
      background: var(--local-sidenavbar-item-background-color);
      
      // CONTENT
      display: flex;
      align-items: center;
  
      // SHAPE
      height: var(--local-sidenavbar-item-height);
      padding: 0 var(--local-sidenavbar-item-x-padding);
      margin: var(--local-sidenavbar-item-gap) 0;
      border-top: var(--local-sidenavbar-item-border-width) solid var(--local-sidenavbar-item-border-color);
      border-right:  var(--local-sidenavbar-item-border-width) solid var(--local-sidenavbar-item-border-color);
      border-bottom:  var(--local-sidenavbar-item-border-width) solid var(--local-sidenavbar-item-border-color);
  
      &.inside {
        margin-left: 0.3rem;
        border-top-left-radius: var(--local-border-radius);
        border-bottom-left-radius: var(--local-border-radius);
      }
      &.outside {
        margin-right: 0.3rem;
        border-top-right-radius: var(--local-border-radius);
        border-bottom-right-radius: var(--local-border-radius);
      }
  
      .text-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        // UNDERLINE
        /* Needed positionning */
        position: relative;
        --underline-color: var(--local-sidenavbar-item-underline-color);
        --underline-hover-color: var(--local-sidenavbar-hover-underline-color);
        --underline-active-color: var(--local-sidenavbar-active-underline-color);
        --underline-height: var(--local-nav-underline-height);
        --underline-width: var(--local-sidenavbar-item-underline-width);
        --underline-thickness: var(--local-nav-underline-thickness);
        @include underline;
      }
      
      // APPEARANCE
      color: var(--local-sidenavbar-item-text-color);
      text-decoration: none;
  
      transition: var(--local-element-transition-duration);
      -webkit-transition: var(--local-element-transition-duration);
      &:hover {
        color: var(--local-sidenavbar-hover-text-color);
        background-color: var(--local-sidenavbar-hover-background-color);
        border-color: var(--local-sidenavbar-hover-border-color);
      }
  
      &.active {
        color: var(--local-sidenavbar-active-text-color);
        background-color: var(--local-sidenavbar-active-background-color);
        border-color: var(--local-sidenavbar-active-border-color);
      }
  
      // DISPLAY ITEMS DEPENDING ON SCREEN SIZE
      &.topnavbar-item {
        /* Items from topnavbar are not visible in sidenavbar on desktops */
        display: none;
      }
      @media screen and (max-width: 768px) {
        /* item from topnavbar item are displayed in the sidenavbar on small screens */
        &.topnavbar-item {
          display: flex;
        }
      }
    }
  }
}
