:root {
  --menu-box-padding: 0.5rem;
  --menu-item-hover-color: var(--button-hover-background-color, #565763);
  --menu-item-active-color: var(--primary, rgb(13, 24, 34));
  --menu-item-padding-x: 1rem;
  --menu-item-padding-y: 0.3rem;
  --menu-item-border-radius: var(--border-radius);
}

.menu-button > .box {
  // CONTENT
  flex-direction: column;

  > .box-content {
    padding: var(--menu-box-padding);
  }

  /** MENU ITEMS */
  .item {
    cursor: pointer;
    text-decoration: none;

    box-sizing: border-box;
    width: 100%;
    padding: var(--menu-item-padding-y) var(--menu-item-padding-x);
    border-radius: calc(var(--menu-item-border-radius) - var(--menu-box-padding) / 2);

    transition: var(--element-transition-duration);
    -webkit-transition: var(--element-transition-duration);
  }
  .item:hover {
    background-color: var(--menu-item-hover-color);
    color: var(--box-background-color);
    label {
      cursor: pointer;
      color: var(--box-background-color);
      transition: color var(--element-transition-duration) ease 0s;
    }
  }
  .item:active {
    background-color: var(--menu-item-active-color);
  }
  
  svg g {
    stroke: var(--menu-item-hover-color);
  }
  .item:hover svg g {
    stroke: var(--box-background-color);
  }
}
