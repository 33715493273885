:root {
  --button-height: 2em;
  --button-padding-y: .3rem;
  --button-padding-x: 1rem;
  --button-margin: 1.5rem;
  --button-border-width: var(--border-width);
  --button-border-radius: var(--border-radius);
  /** Content */
  --button-font-name: var(--font-name);
  /** Colors */
  --button-text-color: var(--blue);
  --button-background-color: var(--content-background-color);
  --button-border-color: var(--button-background-color);
  /** Hover colors */
  --button-hover-text-color: var(--button-background-color);
  --button-hover-background-color: var(--button-text-color);
  --button-hover-border-color: var(--button-text-color);
  /** Active colors */
  --button-active-text-color: var(--button-background-color);
  --button-active-background-color: var(--button-text-color);
  --button-active-border-color: var(--button-background-color);
  /** Inverse colors */
  --button-inverse-text-color: var(--button-hover-text-color);
  --button-inverse-background-color: var(--button-hover-background-color);
  --button-inverse-noborder-color: var(--button-inverse-background-color);
  --button-inverse-border-color: var(--button-inverse-background-color);
  /** Inverse hover colors */
  --button-inverse-hover-text-color: var(--button-text-color);
  --button-inverse-hover-background-color: var(--button-background-color);
  --button-inverse-hover-noborder-color: var(--button-border-color);
  --button-inverse-hover-border-color: var(--button-inverse-hover-text-color);
  /** Inverse active colors */
  --button-inverse-active-text-color: var(--button-inverse-text-color);
  --button-inverse-active-background-color: var(--button-inverse-background-color);
  --button-inverse-active-noborder-color: var(--button-inverse-active-text-color);
  --button-inverse-active-border-color: var(--button-inverse-active-text-color);
  /** Disabled color */
  --button-disabled-text-color: #888;
  --button-disabled-background-color: var(--button-background-color);
  --button-disabled-border-color: #888;
  /** Transitions */
  --button-transition-duration: var(--element-transition-duration);
}

.button {
  --local-button-height: var(--button-height);
  --local-button-padding-y: var(--button-padding-y);
  --local-button-padding-x: var(--button-padding-x);
  --local-button-margin: var(--button-margin);
  --local-button-border-width: var(--button-border-width);
  --local-button-border-radius: var(--button-border-radius);
  /** Content */
  --local-button-font-name: var(--button-font-name);
  /** Colors */
  --local-button-text-color: var(--button-text-color);
  --local-button-background-color: var(--button-background-color);
  --local-button-border-color: var(--button-border-color);
  /** Hover colors */
  --local-button-hover-text-color: var(--button-hover-text-color);
  --local-button-hover-background-color: var(--button-hover-background-color);
  --local-button-hover-border-color: var(--button-hover-border-color);
  /** Active colors */
  --local-button-active-text-color: var(--button-active-text-color);
  --local-button-active-background-color: var(--button-active-background-color);
  --local-button-active-border-color: var(---button-active-border-color);
  /** Inverse colors */
  --local-button-inverse-text-color: var(--button-inverse-text-color);
  --local-button-inverse-background-color: var(--button-inverse-background-color);
  --local-button-inverse-noborder-color: var(--button-inverse-noborder-color);
  --local-button-inverse-border-color: var(--button-inverse-border-color);
  /** Inverse hover colors */
  --local-button-inverse-hover-text-color: var(--button-inverse-hover-text-color);
  --local-button-inverse-hover-background-color: var(--button-inverse-hover-background-color);
  --local-button-inverse-hover-noborder-color: var(--button-inverse-hover-noborder-color);
  --local-button-inverse-hover-border-color: var(--button-inverse-hover-border-color);
  /** Inverse active colors */
  --local-button-inverse-active-text-color: var(--button-inverse-active-text-color);
  --local-button-inverse-active-background-color: var(--button-inverse-active-background-color);
  --local-button-inverse-active-noborder-color: var(--button-inverse-active-noborder-color);
  --local-button-inverse-active-border-color: var(--button-inverse-active-border-color);
  /** Disabled color */
  --local-button-disabled-text-color: var(--button-disabled-text-color);
  --local-button-disabled-background-color: var(--button-disabled-background-color);
  --local-button-disabled-border-color: var(--button-disabled-border-color);
  /** Transitions */
  --local-button-transition-duration: var(--button-transition-duration);

  /* APPEARANCE */
  cursor: pointer;
  outline: none;
  border-style: solid;
  user-select: none;

  // SHAPE
  min-height: var(--local-button-height);
  min-width: var(--local-button-height);
  border-width: var(--local-button-border-width);
  border-radius: var(--local-button-border-radius);
  padding: var(--local-button-padding-y) var(--local-button-padding-x);

  // CONTENT
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--local-button-font-name);
  /* Get the font size to resize locally */
  font-size: 1em;
  svg {
    /* Resize svg at the same size that the button */
    height: calc(var(--local-button-height) - 2 * var(--local-button-border-width));
    /* Resize width as well: auto => keeps ratio */
    width: auto;
  
    g {
      stroke: var(--local-button-text-color);
      transition:
        fill var(--local-button-transition-duration) ease 0s,
        stroke var(--local-button-transition-duration) ease 0s;
    }
  }

  /* COLORS */
  color: var(--local-button-text-color);
  background: var(--local-button-background-color);
  border-color: transparent;
  &::after,
  &::before {
    color: var(--local-button-text-color);
  }
  .stroke {
    stroke: var(--local-button-text-color);
  }
  .fill {
    fill: var(--local-button-text-color);
  }

  transition:
    color var(--local-button-transition-duration) ease 0s,
    background-color var(--local-button-transition-duration) ease 0s,
    border-color var(--local-button-transition-duration) ease 0s,
    transform var(--local-button-transition-duration) ease 0s;

  &.border {
    border-color: var(--local-button-text-color);
  }

  &.inverse-colors {
    color: var(--local-button-inverse-text-color);
    background: var(--local-button-inverse-background-color);
    border-color: var(--local-button-inverse-noborder-color);
    &::after,
    &::before {
      color: var(--local-button-inverse-text-color);
    }
    svg g {
      stroke: var(--local-button-inverse-text-color);
    }
    .stroke {
      stroke: var(--local-button-inverse-text-color);
    }
    .fill {
      fill: var(--local-button-inverse-text-color);
    }
  
  }
}

@media (hover: hover) {

  .button:hover,
  .button.hover {
    color: var(--local-button-hover-text-color);
    background: var(--local-button-hover-background-color);
    border-color: var(--local-button-hover-border-color);
    svg g {
      stroke: var(--local-button-hover-text-color);
    }
    .stroke {
      stroke: var(--local-button-hover-text-color);
    }
    .fill {
      fill: var(--local-button-hover-text-color);
    }
  }

  .button.inverse-colors:hover,
  .button.inverse-colors.hover {
    color: var(--local-button-inverse-hover-text-color);
    background-color: var(--local-button-inverse-hover-background-color);
    border-color: var(--local-button-inverse-hover-noborder-color);
    svg g {
      stroke: var(--local-button-inverse-hover-text-color);
    }
    .stroke {
      stroke: var(--local-button-inverse-hover-text-color);
    }
    .fill {
      fill: var(--local-button-inverse-hover-text-color);
    }  
  }

  .button.inverse-colors.border:hover,
  .button.inverse-colors.border.hover {
    border-color: var(--local-button-inverse-hover-border-color);
  }

}

.button:active,
.button.active {
  color: var(--local-button-active-text-color);
  background: var(--local-button-active-background-color);
  border-color: var(--local-button-active-border-color);
  svg g {
    stroke: var(--local-button-active-text-color);
  }
  .stroke {
    stroke: var(--local-button-active-text-color);
  }
  .fill {
    fill: var(--local-button-active-text-color);
  }
}

.button.inverse-colors:active,
.button.inverse-colors.active {
  color: var(--local-button-inverse-active-text-color);
  background: var(--local-button-inverse-active-background-color);
  border-color: var(--local-button-inverse-active-noborder-color);
  svg g {
    stroke: var(--local-button-inverse-active-text-color);
  }
  .stroke {
    stroke: var(--local-button-inverse-active-text-color);
  }
  .fill {
    fill: var(--local-button-inverse-active-text-color);
  }
}

.button.inverse-colors.border:active,
.button.inverse-colors.border.active {
  border-color: var(--local-button-inverse-active-border-color);
}

/* END Colors */

.button.nopadding {
  padding: 0;
}

.button.round {
  /* Make round corner for all sizes */
  border-radius: 1000rem;
}

.button.square {
  height: var(--local-button-height);
  width: var(--local-button-height);
}

.button.noresize {
  /* Keep the button from resizing */
  font-size: 1rem;
}

.button:disabled {
  pointer-events: none;
  color: var(--local-button-disabled-text-color);
  background: var(--local-button-disabled-background-color);
  border-color: var(--local-button-disabled-background-color);

  &::after,
  &::before {
    color: var(--local-button-disabled-text-color);
  }
  svg g {
    stroke: var(--local-button-disabled-text-color);
  }
  .stroke {
    stroke: var(--local-button-disabled-text-color);
  }
  .fill {
    fill: var(--local-button-disabled-text-color);
  }
}

.button:disabled.border {
  border-color: var(--local-button-disabled-border-color);
}