.select-set-button  {
  --button-text-color: var(--text-color);
  --button-hover-background-color: var(--button-text-color);
  
  --select-set-row-padding: 0.2rem;
  --select-set-container-padding: 0.5rem;
  
  width: 100%;
  border: var(--input-border-width) solid var(--input-border-color);
  box-shadow: var(--input-shadow);
  margin-top: var(--input-margin);

  .header-sets {
    display: flex;
    justify-content: space-evenly;
  }

  .next-sets {
    max-width: 300px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    @media only screen and (min-width: 481px) {
      margin: 0 1rem;
    }

    .column-header {
      text-align: center;
      font-weight: lighter;
      margin-bottom: var(--select-set-container-padding);
      padding-bottom: var(--select-set-container-padding);
      border-bottom: var(--border-width, 1px) solid var(--border-color);
    }

    .d-day {
      font-weight: bolder;
    }

    .days-container,
    .total-container {
      padding: var(--select-set-container-padding) 0;
      .day,
      .total {
        text-align: center;
        padding: var(--select-set-row-padding);
      }
    }
    .sets-container {
      display: flex;
      flex-direction: column;
      padding: var(--select-set-container-padding) 0;

      .sets {
        display: flex;
        justify-content: space-around;
        padding: var(--select-set-row-padding);
        .set {
          text-align: center;
          margin: 0 0.5rem;
        }
      }
    }
  }
}
