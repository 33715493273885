.sidebar {
  // DEFAULT VALUES
  --sidebar-width: 20em;
  --sidebar-text-color: var(--section-text-color, #565763);
  --sidebar-background-color: var(--section-background-color, #ffffff);
  --sidebar-transition-duration: var(--layout-transition-duration, .2s);
  --sidebar-align-items: baseline; // [baseline, center, end]

  // POSITION
  position: relative;
  z-index: 200; // to be above toolbar (z-index: 100)

  // CONTENT
  overflow: hidden;
  text-decoration: none;
  border: none;
  background-color: var(--sidebar-background-color);
  color: var(--sidebar-text-color);

  // SHAPE
  height: 100%;
  min-width: var(--sidebar-width);
  width: var(--sidebar-width);

  transition: var(--sidebar-transition-duration);
  -webkit-transition: var(--sidebar-transition-duration);

  /* The container keeps content from stretching */
  .sidebar-items {
    // SHAPE
    width: var(--sidebar-width); // Make the content keep its layout when opening
    height: 100%;

    // CONTENT
    display: flex;
    flex-direction: column;
    align-items: var(--sidebar-align-items);
  }

  &.is-open {
    min-width: var(--sidebar-width);
    width: var(--sidebar-width);
  }
  &.is-closed {
    min-width: 0;
    max-width: 0;
    width: 0;
  }
}
