.histogram {
  transition: none !important; // Remove all transitions

  .history-column rect {
    transition:
      height var(--element-transition-duration) ease 0s,
      width var(--element-transition-duration) ease 0s,
      y var(--element-transition-duration) ease 0s,
      x var(--element-transition-duration) ease 0s;
  }

  .svg-tooltip {
    filter: drop-shadow(0px 0px 4px var(--shadow-color)); // Doesn't work on Safari for unknown reasons
  }
  .svg-tooltip.hidden {
    opacity: 0;
    visibility: hidden;
    transition:
      y var(--element-transition-duration) ease 0s,
      opacity var(--element-transition-duration) ease 0s,
      visibility 0s ease var(--element-transition-duration);
  }

  .svg-tooltip:hover,
  .svg-tooltip.visible {
    opacity: 1;
    visibility: visible;
    transition:
      y var(--element-transition-duration) ease 0s,
      opacity var(--element-transition-duration) ease 0s,
      visibility 0s ease 0s;
  }
}

.histogram.no-data-message {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}