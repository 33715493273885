@import "../../styles/screen-sizes.scss";

:root {
    --navbar-position: sticky;
    --navbar-background-color: var(--layout-background-color, #ffffff);
    --navbar-text-color: var(--text-color, #565763);
    --navbar-height: 4rem;
    --navbar-min-height: calc(var(--navbar-height) * 2 / 3);
    --navbar-top-padding: 0;
    --navbar-bottom-padding: 0;
    --navbar-x-padding: 1rem;
    --navbar-border-width: var(--layout-border-width, 1px);
    --navbar-border-color: var(--border-color, #565763);
}

.navbar {
  // POSITION
  /* Set in layout.scss */
  /* position: var(--navbar-position); */

  // SHAPE
  height: var(--navbar-height);
  min-height: var(--navbar-min-height);
  /* Keeps header from stretch on small screens when content is pushed */
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  // overflow: hidden;

  // CONTENT
  display: flex;
  // align-items: center;
  padding-top: var(--navbar-top-padding);
  padding-right: var(--navbar-x-padding);
  padding-bottom: var(--navbar-bottom-padding);
  padding-left: var(--navbar-x-padding);
  transition-duration: var(--element-transition-duration);

  // APPEARANCE
  background-color: var(--navbar-background-color);
  box-shadow: var(--small-shadow);
  border-top: var(--navbar-border-width) solid var(--navbar-border-color);
  border-bottom: none;
  @media only screen and (min-width: 481px){
    border-top: none;
    border-bottom: var(--navbar-border-width) solid var(--navbar-border-color);
  }

  #hamburger {
    align-self: center;
    @media only screen and (min-width: 1201px) {
      /* Hide burger button on big screen because sidenavbar is always open */
      visibility: hidden;
    }
  }
  #brand {
    // POSITION
    align-self: center;
    // CONTENT
    display: flex;
    align-items: center;
    overflow: hidden;

    // SHAPE
    margin-left: 1rem;
    margin-right: auto;
    
    // APPEARANCE
    text-decoration: none;
    font-size: 2em;
    color: var(--navbar-text-color);
    transition: var(--navbar-transition-duration);
    &[hidden] {
      visibility: hidden;
    }
      
    // LOGO
    svg {
      --logo-width: 3rem;
      margin-right: 0.5rem;
      height: var(--logo-width);
      width: var(--logo-width);
      min-width: var(--logo-width);

      g {
        fill: var(--navbar-text-color);
      }
    }

    /* Keep title from breaking into multiple lines */
    .title {
      white-space: nowrap;
      overflow: hidden;
    }
  }
  #topnavbar {
    align-self: flex-end;
    margin-right: 2rem;
  }
  #profile-button {
    align-self: center;

    img {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: var(--border-radius);
    }
  }
}
