.edit-exercise {
  overflow: hidden;
}

.edit-exercise.visible {
  opacity: 1;
  visibility: visible;
  height: calc(
    6 * var(--input-height)
    + 12 * var(--input-margin)
    + 2 * var(--button-margin)
    + var(--button-height)
  );
  transition:
    opacity var(--element-transition-duration) ease 0s,
    height var(--element-transition-duration) ease 0s,
    visibility 0s ease 0s;
}

.edit-exercise.hidden {
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition:
    visibility 0s ease var(--element-transition-duration),
    opacity var(--element-transition-duration) ease 0s,
    height var(--element-transition-duration) ease 0s;
}

.edit-exercise__buttons {
  display: flex;
  justify-content: space-between;
  margin: var(--button-margin) 0;
}

.edit-exercise__buttons .save-button {
  margin-left: auto;
}