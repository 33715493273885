:root {
  --popup-background-color--rgb: var(--layout-background-color--rgb, #fff);
  --popup-background-color: transparent; // rgba(var(--popup-background-color--rgb), 0.8);
  --popup-padding: 0.5rem;
  /* positionning the box next to the button with margin value */
  --popup-gap-with-button: 3px;
  --popup-gap-with-container: 1rem;

  --triangle-size: 12px;
}

.popup-button-container {
  --layout-border-width: 1px;
  position: relative;
  // height: fit-content;
  // width: fit-content;

  >.popup-button {
    /* For box and triangle absolute positions to be relative to the button */
    position: relative;

    // SHAPE
    width: 100%;
    max-width: 100%;
    height: var(--button-height, 2em);

    // APPEARENCE
    &.plain {
      background-color: var(--button-background-color);
    }

    // CONTENT
    /* Center triangle */
    display: flex;
    align-items: center;

    >.popup-button-label {
      display: flex;
      align-content: center;
    }
  }


  /** TRIANGLE */
  >.popup-button.triangle::after {
    content: "";
    /* Make a 8px transparent square only made of borders (4px height) */
    border: 0.25em solid transparent;
    /* Change top border color to reveal the triangle */
    border-top-color: var(--button-text-color);
    /* Borders join to the center */
    width: 0;
    height: 0;

    /* Base = 2 * Height = 8px */
    /* Push triangle to the bottom to center it */
    margin-top: 0.25em;
    /* Push triangle to the right to create space with left content */
    margin-left: 0.5em;

    transition: var(--element-transition-duration);
  }

  >.popup-button.triangle:hover::after,
  >.popup-button.active.triangle::after {
    border-top-color: var(--button-background-color);
  }

  >.popup-button.popup-button.triangle:disabled::after {
    border-top-color: var(--gray-light);
  }

  /** BOX **/
  >.popup {
    /* POSITION */
    position: absolute;
    /* !!! 
      max-height, max-width, top, right, bottom, left are Javascript calculated
    */

    /* SHAPE */
    border-radius: var(--border-radius);
    margin: 0;

    /* APPEARANCE */
    cursor: auto;
    border: var(--layout-border-width) solid var(--border-color);

    background: var(--popup-background-color);
    backdrop-filter: saturate(180%) blur(10px);
    box-shadow: var(--large-medium-shadow);

    /*
    &::before {
      content: "";
      clip-path: polygon(0 0, 100% 0, 50% 50%);
      position: absolute;
      z-index: 9999;
    
      width: var(--triangle-size);
      height: var(--triangle-size);
    
      background-color: var(--popup-background-color);
      border-bottom: var(--layout-border-width) solid var(--border-color);
      border-right: var(--layout-border-width) solid var(--border-color);

      bottom: calc(-1 * (var(--triangle-size) - 0.5px) + var(--layout-border-width)); // 0.5px is to close a little gap that appears for no reason
      left: calc(2 * var(--border-radius));
    }
    */

    transition: var(--element-transition-duration);

    >.popup-content {
      /* CONTENT */
      text-align: left;
      box-sizing: border-box;
      padding: var(--popup-padding);

      h1:first-child,
      h2:first-child,
      h3:first-child,
      h4:first-child,
      h5:first-child,
      h6:first-child {
        margin-top: 0;
      }
    }
  }

  >.popup.hidden {
    visibility: hidden;
    opacity: 0;
  }

  &:hover >.popup.hover,
  >.popup.visible {
    z-index: 9999;
    visibility: visible;
    opacity: 1;
  }

  >.element.click {
    cursor: pointer;
  }
}