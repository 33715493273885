.period-item {
  outline: 0;
  padding: 0.2rem 0.5rem;
  width: 25%;
  min-width: max-content;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--gray);
  background: var(--content-background-color);

  &:first-of-type {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }

  &:last-of-type {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }

  @media (hover: hover) {

    &.hover,
    &:hover {
      color: var(--button-background-color);
      background: var(--gray3);
    }
  }

  &.active {
    color: var(--button-background-color);
    background: var(--button-text-color);
  }


  transition: color var(--element-transition-duration) ease 0s,
  background-color var(--element-transition-duration) ease 0s;
}