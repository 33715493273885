#components {
  .hover-me {
    position: relative;
    display: inline-block;

    height: fit-content;
    padding: var(--button-padding-y) var(--button-padding-x);
    color: var(--button-text-color);
    border: var(--border-width) solid var(--button-text-color);
    border-radius: var(--border-radius);
    background: var(--button-background-color);
    box-sizing: border-box;

    text-align: center;

    &:hover .tooltip {
      color: var(--text-color);
      visibility: visible;
      opacity: 1;
    }
    &:hover .svg-tooltip,
    .svg-tooltip:hover {
      visibility: visible;
      opacity: 1;
    }
  }

  .svg-tooltip {
    position: absolute;
    z-index: 9999;
    &.top  {
      bottom: 100%;
      right: 50%;
      transform: translateX(50%);
    }
    &.right {
      bottom: 50%;
      transform: translateY(50%);
      left: 100%;
    }
    &.bottom {
      top: 100%;
      right: 50%;
      transform: translateX(50%);
    }
    &.left {
      bottom: 50%;
      transform: translateY(50%);
      right: 100%;
    }

    visibility: hidden;
    opacity: 0;
    transition-duration: var(--element-transition-duration);
    
    g {
      stroke: var(--border-color);
      fill: var(--layout-background-color);
    }
  }

  .rigid-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    box-sizing: border-box;
    width: 100%;
    height: 200px;
    margin: 1rem auto;
    padding: 1rem;

    border: max(1px, var(--border-width)) solid var(--border-color);
    border-radius: var(--border-radius);
  }
}