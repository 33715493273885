#sets {
  user-select: none;
  height: 100%;

  &.set-colors {
    --sets-text-color: var(--text-color);
    --sets-background-color: var(--content-background-color);
    --button-text-color: var(--text-color);
    --button-background-color: var(--content-background-color);
    --button-hover-text-color: var(--content-background-color);
    --button-hover-background-color: var(--text-color);
    --button-hover-border-color: var(--text-color);
    --button-active-text-color: var(--content-background-color);
    --button-active-background-color: var(--text-color);
    --button-active-border-color: var(--content-background-color);
  }

  &.inverse-colors {
    --sets-text-color: var(--content-background-color);
    --sets-background-color: var(--text-color);
    --button-text-color: var(--content-background-color);
    --button-background-color: var(--text-color);
    --button-hover-text-color: var(--text-color);
    --button-hover-background-color: var(--content-background-color);
    --button-hover-border-color: var(--content-background-color);
    --button-active-text-color: var(--text-color);
    --button-active-background-color: var(--content-background-color);
    --button-active-border-color: var(--text-color);
  }

  color: var(--sets-text-color);
  background: var(--sets-background-color);
  transition-property: color,
  background-color;
  transition-duration: var(--element-transition-duration);
  transition-timing-function: ease;
  transition-delay: 0s;

  .set-text-color {
    color: var(--sets-text-color);
  }

  .inverse-text-color {
    color: var(--sets-background-color);
  }

  .set-background-color {
    background: var(--sets-background-color);
  }

  .inverse-background-color {
    background: var(--sets-text-color);
  }

  .toolbar {
    color: var(--sets-text-color);
  }

  .button {
    transition-property: color, background-color, border-color;
    transition-duration: var(--element-transition-duration);
    transition-timing-function: ease;
    transition-delay: 0s;

    svg g {
      transition-property: fill, stroke;
      transition-duration: var(--element-transition-duration);
      transition-timing-function: ease;
      transition-delay: 0s;
    }

    .set-button-colors {
      --button-text-color: var(--text-color);
      --button-background-color: transparent;
      --button-hover-text-color: var(--content-background-color);
      --button-hover-background-color: var(--text-color);
      --button-border-color: var(--text-color);
    }

    .inverse-button-colors {
      --button-text-color: var(--content-background-color);
      --button-background-color: transparent;
      --button-hover-text-color: var(--text-color);
      --button-hover-background-color: var(--content-background-color);
      --button-border-color: var(--content-background-color);
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100%;
    background: transparent;
  }

  .sets-bar {
    margin: 2em 0 1em 0;
    padding: 1.1em;
    width: -webkit-fill-available;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: var(--border-radius);
    position: relative;

    .sets-bar-background {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      background: var(--sets-text-color);
      opacity: 0.4;
      transition: background-color var(--element-transition-duration) ease 0s;
    }

    .sets-bar-title {
      position: absolute;
      left: .5rem;
      top: .2rem; // -1.2rem;
      font-size: .7rem;
      color: var(--sets-text-color);
      transition: color var(--element-transition-duration) ease 0s;

      &.hide {
        color: transparent;
      }
    }

    .sets-bar-subtitle {
      position: absolute;
      right: .5rem;
      bottom: .2rem; //-1.2rem;
      font-size: .7rem;
      color: var(--sets-background-color);
      transition: color var(--element-transition-duration) ease 0s;
    }

    .reps-container {
      position: relative;
      --round-size: 1.8em;
      height: var(--round-size);
      width: var(--round-size);
      border-radius: var(--border-radius); // var(--round-size);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      transition: color var(--element-transition-duration) ease 0s;

      .reps-container-background {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: var(--border-radius);
        opacity: 0.8;
        transition: background-color var(--element-transition-duration) ease 0s;
      }

      .reps-done {
        position: relative;

        &.hide {
          color: transparent;
        }
      }

      .reps-to-do {
        position: absolute;
        transform: translate(50%, 50%);
        transition:
          font-size var(--element-transition-duration) ease 0s,
          bottom var(--element-transition-duration) ease 0s,
          right var(--element-transition-duration) ease 0s;

        &.plain {
          font-size: 1.5rem;
          bottom: 50%;
          right: 50%;
        }

        &.reduce {
          bottom: 0.5rem;
          right: 0.5rem;
          font-size: 0.9rem;
        }
      }
    }

  }

  .center-container {
    width: 100%;

    .counter-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1.5em; // Height of the subtitle font-size to keep count in the middle
      color: var(--sets-text-color);

      .timer {
        font: inherit;
        border: none;
        background: none;
        color: inherit;
        text-align: center;
        font-size: 8em;
        width: 100%;
      }

      .counter {
        cursor: pointer;
        text-align: center;
        font-size: 8em;
        outline: 0;
      }
    }

    .subtitle {
      text-align: center;
      font-size: 1.5em;
      color: var(--sets-text-color);
    }
  }

  .bottom-container {
    width: 100%;

    .footer {
      padding: 1rem 0;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--sets-text-color);

      >div {
        margin: 1rem;
      }

      .settings {
        display: flex;
        align-items: center;
        gap: 1rem;
        transition: color var(--element-transition-duration) ease 0s;
      }
    }


    .buttons {
      width: 100%;
      font-size: 1.5em;
      display: flex;
      justify-content: space-around;
      gap: 1rem;

      button {
        width: 100%;
      }
    }
  }

}