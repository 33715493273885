@import './underline.scss';
:root {
  --topnavbar-item-height: var(--nav-item-height);
  --topnavbar-item-width: fit-content; /* fit-content not supported by Edge & IE */
  --topnavbar-item-gap: var(--nav-item-gap);
  --topnavbar-item-x-padding: var(--nav-item-x-padding);
  --topnavbar-item-border-width: var(--nav-item-border-width);
  --topnavbar-item-underline-width: var(--nav-item-underline-width);

  --topnavbar-item-text-color: var(--navbar-text-color);
  --topnavbar-item-underline-color: var(--navbar-background-color);
  --topnavbar-item-background-color: var(--navbar-background-color);
  --topnavbar-item-border-color: var(--topnavbar-item-text-color);

  --topnavbar-hover-text-color: var(--navbar-background-color);
  --topnavbar-hover-underline-color: var(--topnavbar-hover-text-color);
  --topnavbar-hover-background-color: var(--nav-hover-background-color);
  --topnavbar-hover-border-color: var(--topnavbar-hover-background-color);

  --topnavbar-active-text-color: var(--navbar-background-color);
  --topnavbar-active-underline-color: var(--topnavbar-active-text-color);
  --topnavbar-active-background-color: var(--nav-active-background-color);
  --topnavbar-active-border-color: var(--topnavbar-active-background-color);
}

.topnavbar {
  --local-topnavbar-item-height: var(--topnavbar-item-height, var(--nav-item-height, 3rem));
  --local-topnavbar-item-width: var(--topnavbar-item-width, fit-content); /* fit-content not supported by Edge & IE */
  --local-topnavbar-item-gap: var(--topnavbar-item-gap, var(--nav-item-gap, .3rem));
  --local-topnavbar-item-x-padding: var(--topnavbar-item-x-padding, var(--nav-item-x-padding, 1rem));
  --local-topnavbar-item-border-width: var(--topnavbar-item-border-width, var(--nav-item-border-width, var(--border-width, 1px)));
  --local-topnavbar-item-underline-width: var(--topnavbar-item-underline-width, var(--nav-item-underline-width, 100%));

  --local-topnavbar-item-text-color: var(--topnavbar-item-text-color, var(--navbar-text-color, var(--text-color, #565763)));
  --local-topnavbar-item-underline-color: var(--topnavbar-item-underline-color, var(--navbar-background-color, var(--layout-background-color, var(--color1, #ffffff))));
  --local-topnavbar-item-background-color: var(--topnavbar-item-background-color, var(--navbar-background-color, var(--layout-background-color, var(--color1, #ffffff))));
  --local-topnavbar-item-border-color: var(--topnavbar-item-border-color, var(--topnavbar-item-text-color, var(--navbar-text-color, var(--text-color, #565763))));

  --local-topnavbar-hover-text-color: var(--topnavbar-hover-text-color, var(--navbar-background-color, var(--layout-background-color, var(--color1, #ffffff))));
  --local-topnavbar-hover-underline-color: var(--topnavbar-hover-underline-color, var(--topnavbar-hover-text-color));
  --local-topnavbar-hover-background-color: var(--topnavbar-hover-background-color, var(--nav-hover-background-color, var(--color5, #565763)));
  --local-topnavbar-hover-border-color: var(--topnavbar-hover-border-color, var(--topnavbar-hover-background-color));

  --local-topnavbar-active-text-color: var(--topnavbar-active-text-color, var(--navbar-background-color, var(--layout-background-color, var(--color1, #ffffff))));
  --local-topnavbar-active-underline-color: var(--topnavbar-active-underline-color, var(--topnavbar-active-text-color));
  --local-topnavbar-active-background-color: var(--topnavbar-active-background-color, var(--nav-active-background-color, var(--primary, rgb(13, 24, 34))));
  --local-topnavbar-active-border-color: var(--topnavbar-active-border-color, var(--topnavbar-active-background-color));

  // CONTENT
  display: flex;
  align-items: center;

  // SHAPE
  box-sizing: border-box;
  height: var(--local-topnavbar-item-height);

  // Set margins to all children
  >:nth-child(n) {
    margin-right: var(--local-topnavbar-item-gap);
    &:last-child {
      margin-right: 0;
    }
  }

  /****** NAVIGATION ITEMS ******/
  a {
    // SHAPE
    height: var(--local-topnavbar-item-height);
    width: var(--local-topnavbar-item-width);
    padding: 0 var(--local-topnavbar-item-x-padding);
    
    // CONTENT
    display: flex;
    align-items: center;
    justify-content: center;

    // APPEARANCE
    color: var(--local-topnavbar-item-text-color);
    text-decoration: none;
    
    /* For the underline to be able to be equal to the test width */
    .text-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      // UNDERLINE
      /* Needed positionning */
      position: relative;
      --underline-color: var(--local-topnavbar-item-underline-color);
      --underline-hover-color: var(--local-topnavbar-hover-underline-color);
      --underline-active-color: var(--local-topnavbar-active-underline-color);
      --underline-height: var(--local-nav-underline-height);
      --underline-width: var(--local-topnavbar-item-underline-width);
      --underline-thickness: var(--local-nav-underline-thickness);
      @include underline;
    }
    
    border-left: var(--local-topnavbar-item-border-width) solid var(--local-topnavbar-item-border-color);
    border-top:  var(--local-topnavbar-item-border-width) solid var(--local-topnavbar-item-border-color);
    border-right:  var(--local-topnavbar-item-border-width) solid var(--local-topnavbar-item-border-color);
    border-top-left-radius: var(--border-radius, 10px);
    border-top-right-radius: var(--border-radius, 10px);

    background-color: var(--local-topnavbar-item-background-color);
    transition: var(--element-transition-duration, .2s);
    -webkit-transition: var(--element-transition-duration, .2s);

    &:hover {
      color: var(--local-topnavbar-hover-text-color);
      background-color: var(--local-topnavbar-hover-background-color);
      border-left: var(--local-topnavbar-item-border-width) solid var(--local-topnavbar-hover-border-color);
      border-top:  var(--local-topnavbar-item-border-width) solid var(--local-topnavbar-hover-border-color);
      border-right:  var(--local-topnavbar-item-border-width) solid var(--local-topnavbar-hover-border-color);  
    }

    &.active {
      color: var(--local-topnavbar-active-text-color);
      background-color: var(--local-topnavbar-active-background-color);
      border-left: var(--local-topnavbar-item-border-width) solid var(--local-topnavbar-active-border-color);
      border-top:  var(--local-topnavbar-item-border-width) solid var(--local-topnavbar-active-border-color);
      border-right:  var(--local-topnavbar-item-border-width) solid var(--local-topnavbar-active-border-color);  
    }
  }
}
