.overflow-bottom {
  /* SHAPE */
  height: var(--overflow-indicator-height);
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);

  /* APPEARANCE */
  box-shadow: var(--overflow-indicator-shadow);
  &.hidden {
    box-shadow: none;
  }
  
  /*¨CONTENT */
  text-align: center;
  svg {
    width: 1rem;
    height: 1rem;
    margin-top: calc((var(--overflow-indicator-height) - 0.5rem) / 2);
    
    transform: scale(0.8, 0.4) translate(0, -0.7rem);
    path {
      fill: var(--border-color);
      stroke: var(--border-color);
    }

    transition-duration: var(--box-transition-duration);
    opacity: 1;
    &.hidden {
      opacity: 0;
    }
  }
}