.dropdown {
  --dropdown-border-color: var(--input-border-color, #cccccc);
  --dropdown-border-width: 1px;
  --dropdown-background-color: var(--content-background-color, #ffffff);
  --dropdown-hover-background-color: var(--button-hover-background-color, rgb(0, 122, 255));
  --dropdown-transition-duration: var(--element-transition-duration, .2s);
  --dropdown-gap-with-input: 3px;

  // Ensure dropdown list go over other elements
  position: relative;
  z-index: 999;
}

.dropdown input {
  cursor: pointer;
  width: calc(100% - 2 * var(--input-padding));
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.dropdown-list {
  // POSITION
  position: absolute;
  /* input height = font-size + padding */
  top: calc(var(--input-height) + var(--dropdown-gap-with-input));
  right: calc(-1 * var(--dropdown-border-width));

  // APPEARANCE
  &.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity var(--dropdown-transition-duration) ease 0s,
      visibility 0s ease 0s;
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: opacity var(--dropdown-transition-duration) ease 0s,
      visibility 0s ease var(--dropdown-transition-duration);
  }

  background: var(--content-background-color);
  border-style: solid;
  border-color: var(--dropdown-border-color);
  box-shadow: var(--large-dark-shadow);

  // SHAPE
  border-width: var(--dropdown-border-width);
  margin: 0;
  /* Override Safari default agent */
  width: 100%;
  border-radius: var(--border-radius, 10px);

  // CONTENT
  display: flex;
  flex-direction: column;

  .dropdown-item {
    position: relative;
    z-index: 1;
    cursor: pointer;
    padding-right: calc(var(--input-padding) + 2ch) !important;
    width: 100% !important;
    background-color: var(--dropdown-background-color) !important;
    border-top-left-radius: calc(var(--border-radius) - var(--border-width));
    border-bottom-left-radius: calc(var(--border-radius) - var(--border-width));

    &:hover {
      color: var(--dropdown-background-color) !important;
      background-color: var(--dropdown-hover-background-color) !important;
    }
  }
}