:root {
  --toolbar-position: sticky;
  --toolbar-text-color: var(--text-color);
  --toolbar-border-color: var(--toolbar-text-color);
  --toolbar-background-color: transparent;
  --toolbar-button-background-color: var(--content-background-color);
  --toolbar-backdrop-filter: var(--backdrop-filter);
  --toolbar-padding: 1rem;
}

.toolbar {
  --local-toolbar-position: var(--toolbar-position, sticky);
  --local-toolbar-text-color: var(--toolbar-text-color, #565763);
  --local-toolbar-border-color: var(--toolbar-border-color, #565763);
  --local-toolbar-background-color: var(--toolbar-background-color, transparent);
  --local-toolbar-button-background-color: var(--toolbar-button-background-color, #ffffff);
  --local-toolbar-backdrop-filter: var(--toolbar-backdrop-filter, saturate(180%) blur(10px));
  --local-toolbar-padding: var(--toolbar-padding, 1rem);

  /* Reapply colors to button because they rely on variables that can be changed locally.
  If not, buttons keep values from where they have been declared, in Button component */
  &.colored-buttons .button {
    --button-text-color: var(--local-toolbar-text-color);
    --button-background-color: var(--local-toolbar-background-color);
    --button-border-color: var(--local-toolbar-background-color);
    --button-hover-text-color: var(--local-toolbar-button-background-color);
    --button-hover-background-color: var(--local-toolbar-text-color);
    --button-hover-border-color: var(--local-toolbar-text-color);
    --button-active-text-color: var(--local-toolbar-button-background-color);
    --button-active-background-color: var(--local-toolbar-text-color);
    --button-active-border-color: var(--local-toolbar-button-background-color);
  }

  /* POSITION */
  position: var(--local-toolbar-position);
  margin-top: calc(-1 * (2 * var(--toolbar-padding) + var(--button-height)));
  top: 0;
  left: 0;
  // Set toolbar over main content
  z-index: 300;

  /* SHAPE */
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;

  /* CONTENT */
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--local-toolbar-text-color);
  background: var(--local-toolbar-background-color);
  backdrop-filter: none;
  padding: var(--local-toolbar-padding) calc(var(--local-toolbar-padding) / 2);

  >*:not(.left),
  >*:not(.center),
  >*:not(.right) {
    margin: 0 calc(var(--local-toolbar-padding) / 2);
  }

  // Align toolbar elements
  .right {
    margin-left: auto;
  }

  .left {
    margin-right: auto;
  }

  .center {
    margin-left: auto;
    margin-right: auto;
  }
}