.edit-workout-form {
  position: relative;


  >.input.visible {
    opacity: 1;
    visibility: visible;

    transition:
      height var(--element-transition-duration) ease 0s,
      padding var(--element-transition-duration) ease 0s,
      opacity var(--element-transition-duration) ease 0s,
      visibility 0s ease 0s;
  }

  >.input.hidden {
    height: 0;
    padding: 0;
    opacity: 0;
    visibility: hidden;

    transition:
      height var(--element-transition-duration) ease 0s,
      padding var(--element-transition-duration) ease 0s,
      opacity var(--element-transition-duration) ease 0s,
      visibility 0s ease var(--element-transition-duration);
  }

  >.program-button {
    --button-text-color: var(--text-color);
    border: var(--input-border-width) solid var(--input-border-color);
    box-shadow: var(--input-shadow);
    margin: var(--input-margin) 0;

    width: 100%;
    height: var(--input-height);
  }
  
  >.delete-button {
    --button-text-color: var(--red, red);
    margin-top: calc(var(--input-margin) + var(--modal-padding));
  }

  >.save-button {
    margin-left: auto;
    position: sticky;
    bottom: var(--modal-padding);
  }

  .hidden {
    visibility: hidden;
  }
}