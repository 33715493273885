:root {
  --hamburger-size: 2em;
  --hamburger-line-width: 2px;
  --hamburger-color: var(--navbar-text-color);
  --hamburger-background-color: var(--navbar-background-color);
  --hamburger-border-color: transparent;
  --hamburger-border-width: var(--border-width);
  --hamburger-transition-duration: var(--element-transition-duration);
}

.hamburger {
  --local-hamburger-size: var(--hamburger-size, 2em);
  --local-hamburger-line-width: var(--hamburger-line-width,  2px);
  --local-hamburger-color:  var(--hamburger-color, var(--navbar-text-color, var(--text-color, #565763)));
  --local-hamburger-background-color: var(--hamburger-background-color, var(--navbar-background-color, var(--layout-background-color, var(--color1, #ffffff))));
  --local-hamburger-border-color: var(--hamburger-border-color, transparent);
  --local-hamburger-border-width: var(--hamburger-border-width, var(--border-width, 1px));
  --local-hamburger-transition-duration: var(--hamburger-transition-duration, var(--element-transition-duration, .2s));

  --local-hamburger-button-size: calc(var(--local-hamburger-size) - 2 * var(--local-hamburger-border-width));
  --local-hamburger-background-size: calc((var(--local-hamburger-button-size) + 2 * var(--local-hamburger-border-width)) * 1.5);
  /* Divides transition time by 2 as there are 2 transitions consecutively */
  --local-hamburder-line-transition-duration: calc(var(--local-hamburger-transition-duration) / 2);
  /* Each position is pushed up by "line-width/2" to center the lines.
   * A coefficient of 2/3 is applied to the height to make it square. */
  --local-hamburder-line-top-position:     calc(-1 * var(--local-hamburger-button-size)/2 * 2/3 - var(--local-hamburger-line-width) / 2);
  --local-hamburder-line-middle-position:  calc(-1 * var(--local-hamburger-line-width) /2);
  --local-hamburder-line-bottom-position:  calc(     var(--local-hamburger-button-size)/2 * 2/3 - var(--local-hamburger-line-width) / 2);

  // SHAPE
  height: var(--local-hamburger-background-size);
  min-height: var(--local-hamburger-background-size);
  width: var(--local-hamburger-background-size);
  min-width: var(--local-hamburger-background-size);

  border-width: var(--local-hamburger-border-width);
  /* Override button attribute */
  border-color: var(--local-hamburger-border-color) !important;

  // APPEARANCE
  background-color: var(--local-hamburger-background-color);
  @media (hover: hover) {
    &:hover,
    &.hover {
      background-color: var(--local-hamburger-color);
    }
  }

  &:active,
  &.active {
    background-color: var(--local-hamburger-color);
  }
  
  // CONTENT
  display: inline-block;
  
  .hamburger-container {
    position: relative;
  }
  
  .hamburger-line {
    // SHAPE
    width: var(--local-hamburger-button-size);
    height: var(--local-hamburger-line-width);

    // APPEARANCE
    background-color: var(--local-hamburger-color);
    border-radius: var(--local-hamburger-line-width);

    // POSITION
    position: absolute;
    left: calc((var(--local-hamburger-background-size) - var(--local-hamburger-button-size)) / 2 - var(--local-hamburger-border-width) );    
  }

  @media (hover: hover) {
    &:hover,
    &.hover {
      .hamburger-line {
        background-color: var(--local-hamburger-background-color);
      }
    }
  }

  &:active .hamburger-line,
  &.active .hamburger-line {
      background-color: var(--local-hamburger-background-color);
  }
  
  /****** TRANSITIONS ******/
  /* Initial position + Deactivate transition (reverse transition order) */
  .top-line {
    top: var(--local-hamburder-line-top-position);

    transition-property: background-color, transform, top;
    transition-duration: var(--local-hamburger-transition-duration), var(--local-hamburder-line-transition-duration), var(--local-hamburder-line-transition-duration);
    transition-delay: 0s, 0s, var(--local-hamburder-line-transition-duration);
  }
  .middle-line {
    top: var(--local-hamburder-line-middle-position);
    opacity: 1;

    transition-property: background-color, opacity;
    transition-duration: var(--local-hamburger-transition-duration), 0s;
    transition-delay: 0s, var(--local-hamburder-line-transition-duration);
  }
  .bottom-line {
    top: var(--local-hamburder-line-bottom-position);

    transition-property: background-color, transform, top;
    transition-duration: var(--local-hamburger-transition-duration), var(--local-hamburder-line-transition-duration), var(--local-hamburder-line-transition-duration);
    transition-delay: 0s, 0s, var(--local-hamburder-line-transition-duration);
  }
  /* Activate transition */
  &.is-open .top-line {
    top: var(--local-hamburder-line-middle-position);
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);

    /* Change order of animation */
    transition-property: background-color, top, transform;
  }
  &.is-open .middle-line {
    opacity: 0;
  }
  &.is-open .bottom-line {
    top: var(--local-hamburder-line-middle-position);
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);

    /* Change order of animation */
    transition-property: background-color, top, transform;
  }
}
