:root {
  @function hexToRGB($hex) {
    @return red($hex), green($hex), blue($hex);
  }

  /* BASE COLORS */
  /* Light labled colors are intended to be used on light backgrounds */
  --red-light: rgb(255, 59, 48);
  --orange-light: rgb(255, 149, 0);
  --yellow-light: rgb(255, 204, 0);
  --green-light: rgb(52, 199, 89);
  --mint-light: rgb(0, 199, 190);
  --teal-light: rgb(48, 176, 199);
  --cyan-light: rgb(50, 173, 230);
  --blue-light: rgb(0, 122, 255);
  --indigo-light: rgb(88, 86, 214);
  --purple-light: rgb(175, 82, 222);
  --pink-light: rgb(255, 45, 85);
  --brown-light: rgb(162, 132, 94);
  /* For easiness of use, we define short named colors based on colors for light background */
  --red: rgb(255, 59, 48);
  --orange: rgb(255, 149, 0);
  --yellow: rgb(255, 204, 0);
  --green: rgb(52, 199, 89);
  --mint: rgb(0, 199, 190);
  --teal: rgb(48, 176, 199);
  --cyan: rgb(50, 173, 230);
  --blue: rgb(0, 122, 255);
  --indigo: rgb(88, 86, 214);
  --purple: rgb(175, 82, 222);
  --pink: rgb(255, 45, 85);
  --brown: rgb(162, 132, 94);
  /* Dark labeled colors are intended to be used on dark backgrounds */
  --red-dark: rgb(255, 69, 58);
  --orange-dark: rgb(255, 159, 10);
  --yellow-dark: rgb(255, 214, 10);
  --green-dark: rgb(48, 209, 88);
  --mint-dark: rgb(102, 212, 207);
  --teal-dark: rgb(64, 200, 224);
  --cyan-dark: rgb(100, 210, 255);
  --blue-dark: rgb(10, 132, 255);
  --indigo-dark: rgb(94, 92, 230);
  --purple-dark: rgb(191, 90, 242);
  --pink-dark: rgb(255, 55, 95);
  --brown-dark: rgb(172, 142, 104);
  /* Gray for light background */
  --gray: rgb(142, 142, 147);
  --gray2: rgb(174, 174, 178);
  --gray3: rgb(199, 199, 204);
  --gray4: rgb(209, 209, 214);
  --gray5: rgb(229, 229, 234);
  --gray6: rgb(242, 242, 247);
  /* Gray for dark background */
  --gray-dark: rgb(142, 142, 147);
  --gray2-dark: rgb(174, 174, 178);
  --gray3-dark: rgb(199, 199, 204);
  --gray4-dark: rgb(209, 209, 214);
  --gray5-dark: rgb(229, 229, 234);
  --gray6-dark: rgb(242, 242, 247);

  /* THEME COLORS */
  --primary: rgb(13, 24, 34);
  --primary--rgb: 13, 24, 34;
  --color5: #565763;
  --color5--rgb: #{hexToRGB(#565763)};
  --color4: #7c777f;
  --color4--rgb: #{hexToRGB(#7c777f)};
  --color3: var(--gray5);
  --color3--rgb: 229, 229, 234;
  --color2: #fafafa;
  --color2--rgb: #{hexToRGB(#fafafa)};
  --color1: #ffffff;
  --color1--rgb: #{hexToRGB(#ffffff)};

  /* BORDERS */
  --border-width: 1px;
  --border-color: var(--color3);
  --border-radius: 10px;

  --light-shadow-color: rgba(0, 0, 0, 1%);
  --medium-shadow-color: rgba(0, 0, 0, 2.5%);
  --shadow-color: rgba(0, 0, 0, 5%);
  --dark-shadow-color: rgba(0, 0, 0, 7.5%);
  --small-spread: 0 0 1px 1px;
  --large-spread: 0 0 4px 4px;
  --small-light-shadow: var(--small-spread) var(--light-shadow-color);
  --small-medium-shadow: var(--small-spread) var(--medium-shadow-color);
  --small-shadow: var(--small-spread) var(--shadow-color);
  --small-dark-shadow: var(--small-spread) var(--dark-shadow-color);
  --large-light-shadow: var(--large-spread) var(--light-shadow-color);
  --large-medium-shadow: var(--large-spread) var(--medium-shadow-color);
  --large-shadow: var(--large-spread) var(--shadow-color);
  --large-dark-shadow: var(--large-spread) var(--dark-shadow-color);

  /** CONTENT */
  --overflow-indicator-height: 1rem;
  --overflow-indicator-shadow: var(--small-shadow);
  --ombrage-background: rgba(255, 255, 255, 0.2);
  --backdrop-filter: saturate(180%) blur(10px);

  /* TEXT */
  --font-name: Comfortaa;
  --font-size: 16px;
  --font-size-phone: 16px;
  --text-color: var(--color5);
  --link-color: var(--text-color);
  --link-hover-color: var(--primary);
}

*,
*::after,
*::before {
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
}

/****** GENERAL STYLE ******/
html {
  /* COLORS */
  --local-primary: var(--primary, rgb(13, 24, 34)); //rgb(157, 205, 212); //#00D2FF;
  --local-color5: var(--color5, #565763);
  --local-color4: var(--color4, #7c777f);
  --local-color3: var(--color3, #cccccc);
  --local-color2: var(--color2, #fafafa);
  --local-color1: var(--color1, #ffffff);

  /* BORDERS */
  --local-border-width: var(--border-width, 1px);
  --local-border-color: var(--border-color, var(--color3, #cccccc));
  --local-border-radius: var(--border-radius, 10px);
  --local-shadow-color: var(--shadow-color, rgba(0, 0, 0, .05));

  /* TEXT */
  --local-font-name: var(--font-name, "Comfortaa");
  --local-font-size: var(--font-size, 16px);
  --local-font-size-phone: var(--font-size-phone, 16px);
  --local-text-color: var(--text-color, var(--color5, #565763));
  --local-link-color: var(--link-color, var(--text-color, var(--color5, #565763)));
  --local-link-hover-color: var(--link-hover-color, var(--primary, rgb(13, 24, 34)));

  font-size: var(--local-font-size);
  @media screen and (max-width: 481px) {
    font-size: var(--local-font-size-phone);
  }
  color: var(--local-text-color);
  // Hide all scollbars on chromium based browsers
  ::-webkit-scrollbar {
    display: none;
  }
}

@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: normal;
  src: local('Comfortaa'), url('./fonts/Comfortaa_Regular.woff') format('woff');
  font-display: swap;
}

body {
  font-family: var(--local-font-name), sans-serif;
  margin: 0;
}

* {
  /* Remove background when tapping on mobile devices */
  -webkit-tap-highlight-color: transparent; /* EXPERIMENTAL */
}

html, body, #app {
  position: fixed;
  // overflow: hidden; // Removes "refresh by pulling"
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; // Enables “momentum” (smooth) scrolling
}

/****** LINKS ******/
a {
  color: var(--local-link-color);
  transition: color var(--local-element-transition-duration);
  -webkit-transition: color var(--local-element-transition-duration);
}
a:hover {
  color: var(--local-link-hover-color);
}

/****** SVGs with mask layer for hovering transitions ******/
svg g {
  stroke-width: var(--local-line-width);
  stroke-linecap: round;
}

svg .mask {
  fill: var(--local-link-color);
  transition: fill var(--local-element-transition-duration),
    opacity var(--local-element-transition-duration);
  -webkit-transition: fill var(--local-element-transition-duration),
    opacity var(--local-element-transition-duration);
}

svg:hover .mask {
  opacity: 0;
}

input, button {
  /* Override Safari default agent */
  margin: 0;
}

::selection {
  background-color: var(--local-color5, #565763);
  color: var(--local-color1, #ffffff);
}

/* Keeps from resizing content */
.noresize {
  font-size: 1rem !important;
}
