.select-set-modal {

  >.freeset-button {
    width: 15em;
    margin: 0 auto;
  }

  >.free-statement {
    margin: 0 auto 1rem auto;
    width: fit-content;
    font-size: 0.8em;
    color: var(--gray4);
  }

  >.or-statement {
    margin: 1rem auto;
    width: fit-content;
    font-size: 0.8em;
    color: var(--gray4);
  }

  >.select-title {
    margin: 0 auto;
    box-sizing: border-box;
    width: 15em;
    text-align: center;
    padding: 0.3rem 1rem;
    border-bottom: var(--border-width) solid var(--border-color);
    display: flex;
    justify-content: space-between;
  }

  >.select-list {
    height: 290px;
    overflow: scroll;

    >.select-option {
      margin: 0 auto;

      width: 15em;

      --button-text-color: var(--text-color);
      display: flex;
      justify-content: space-between;

      >.option-day {
        margin-left: 0.5rem;
      }

      >.option-total {
        margin-right: 0.5rem;
      }
    }
  }

}