:root {
  --pin-height: 2.5rem;
  --pin-active-color: var(--button-text-color);
}

.pin {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: calc(var(--pin-height) / 6);

  .digit {
    cursor: pointer;
    // color : transparent;
    // text-shadow : 0 0 0 var(--input-text-color);
    caret-color: transparent;
    &::selection {
      background-color: transparent;
      color: var(--pin-active-color);
    }

    font-size: calc(var(--pin-height) * 2 / 3);
    text-align: center;

    box-sizing: border-box;
    height: var(--pin-height);
    width: calc(var(--pin-height) * 2 / 3);

    border-radius: calc(var(--border-radius) * 2 / 3);
    border: var(--border-width) solid var(--border-color);

    &:focus {
      outline: none;
      border-color: var(--pin-active-color);
    }
  }

  .digit:nth-child(3) {
    margin-inline-end: 1rem;
  }
}