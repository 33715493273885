.section {
  --section-font-size: 1em;
  --section-text-color: var(--text-color, #565763);
  --section-background-color: var(--content-background-color, #fafafa);
  --section-background-color--rgb: var(--content-background-color--rgb);
  --section-padding: var(--content-padding, 3rem);

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    --section-padding: var(--content-padding-phablet, 2rem);
  }

  @media only screen and (max-width: 480px) {
    --section-padding: var(--content-padding-phone, 1rem);
  }

  --section-padding-top: calc(var(--button-height) + 2 * var(--toolbar-padding));
  --section-padding-bottom: 3rem;
  --section-min-height: 0px;
  --section-transition-duration: var(--element-transition-duration, 0.3s);

  /* Reapply colors to button because they rely on variables that can be changed locally.
  If not, buttons keep values from where they have been declared, in Button component */
  &.colored-buttons {
    --button-text-color: var(--section-text-color);
    --button-background-color: var(--section-background-color);
    --button-border-color: var(--section-background-color);
    --button-hover-text-color: var(--section-background-color);
    --button-hover-background-color: var(--section-text-color);
    --button-hover-border-color: var(--section-text-color);
    --button-active-text-color: var(--section-background-color);
    --button-active-background-color: var(--section-text-color);
    --button-active-border-color: var(--section-background-color);
    --button-inverse-text-color: var(--section-background-color);
    --button-inverse-background-color: var(--section-text-color);
    --button-inverse-noborder-color: var(--section-text-color);
    --button-inverse-border-color: var(--section-background-color);
    --button-inverse-hover-text-color: var(--section-text-color);
    --button-inverse-hover-background-color: var(--section-background-color);
    --button-inverse-hover-noborder-color: var(--section-background-color);
    --button-inverse-hover-border-color: var(--section-text-color);
    --button-inverse-active-text-color: var(--section-background-color);
    --button-inverse-active-background-color: var(--section-text-color);
    --button-inverse-active-noborder-color: var(--section-background-color);
    --button-inverse-active-border-color: var(--section-bacckground-color);
    --button-disabled-background-color: var(--section-background-color);
  }

  // APPEARANCE
  color: var(--section-text-color);
  //background-repeat: no-repeat;
  background-image: none;
  //background-size: cover;
  background-color: var(--section-background-color);

  // POSITION
  position: relative;
  // scroll-snap-align: start end;

  // SHAPE
  box-sizing: border-box;
  padding: var(--section-padding-top) var(--section-padding) var(--section-padding-bottom) var(--section-padding);
  min-height: var(--section-min-height);
  max-width: 100vw;

  // CONTENT
  /* Define font-size to be able to change it locally with --font-size variable */
  font-size: var(--section-font-size);

  h1 {
    /* Override user-agent style in sections */
    /* Using em to get font-size proportionnal to parent font-size */
    font-size: 2em;
  }

}