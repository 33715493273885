/****** ITEMS UNDERLINE ******/

/* Set "position: relative" to the parent you want it to be position after (ex: text wrapper) */
@mixin underline {
  .underline {
    // DEFAULT VALUES
    --local-underline-color: var(--underline-color);
    --local-underline-hover-color: var(--underline-hover-color);
    --local-underline-active-color: var(--underline-active-color);
    --local-underline-height: var(--underline-height);
    --local-underline-width: var(--underline-width);
    --local-underline-thickness: var(--underline-thickness);
  
    // POSITION
    position: absolute;
    top: 0;
    left: 0;

    // SHAPE
    height: 100%;
    width: var(--local-underline-width);

    // CONTENT
    display: flex;
    // align-items: center;

    &::after {
      // APPEARANCE
      background-color: var(--local-underline-color);
      transition: var(--element-transition-duration);
      -webkit-transition: var(--element-transition-duration);
  
      // POSITION
      position: absolute;
      bottom: var(--local-underline-height);
  
      // CONTENT
      display: block;
      content: '';
  
      // SHAPE
      width: 100%;
      height: var(--local-underline-thickness);
      border-radius: var(--local-underline-height);
    }
  
    &:hover::after {
      background-color: var(--local-underline-hover-color);
    }
  
    &.active::after {
      background-color: var(--local-underline-active-color);
    }
  }
}
