:root {
  --modal-background-color: var(--layout-background-color, #ffffff);
  --modal-shadow: var(--large-medium-shadow, 0 0 4px 4px rgba(0, 0, 0, 2.5%));
  --modal-ombrage-background: var(--ombrage-background, rgba(255, 255, 255, 0.2));
  --modal-backdrop-filter: var(--backdrop-filter, saturate(180%) blur(10px));
  --modal-padding: 2rem;
  --modal-padding-desktop: calc(2 * var(--modal-padding));
  --modal-margin: 1rem;
  --modal-transition-duration: var(--element-transition-duration, 0.2s);
  --modal-border-color: var(--border-color, rgb(229, 229, 234));
  --modal-border-radius: var(--border-radius, 10px);
  --modal-border-width: var(--layout-border-width, 1px);
  --modal-text-color: var(--text-color, #565763);
  --modal-header-height : calc(2em + var(--modal-padding)); // h3 = 1.33em * 1.5 = 1.995 ~ 2em
}

.modal {
  /* Creates a background over the element */
  // POSITION
  position: absolute;
  z-index: 9999;
  top: 0;
  right: 0;
  margin: 0;

  // SHAPE
  width: 100%;
  height: 100%;
  // max-height: 100%; // Javascript calculated to get the "real" height: container.clientHeight
  
  @media screen and (min-width: 481px) {
    --modal-padding: var(--modal-padding-desktop);
    --modal-header-height : calc(2em + var(--modal-padding-desktop));
  }

  // APPEARANCE
  background: var(--modal-ombrage-background);
  backdrop-filter: var(--modal-backdrop-filter);

  visibility: visible;
  opacity: 1;
  transition:
    opacity var(--modal-transition-duration) ease 0s,
    visibility 0s ease 0s;

  &.is-hidden {
    visibility: hidden;
    opacity: 0;
    transition:
      opacity var(--modal-transition-duration) ease 0s,
      visibility 0s ease var(--modal-transition-duration);
  }

  // CONTENT
  display: flex;
  justify-content: center;
  align-items: center;

  >.modal-box {
    --button-background-color: var(--modal-background-color);
    --button-border-color: var(--button-background-color);

    // POSITION
    position: relative;

    // SHAPE
    box-sizing: border-box;
    height: auto;
    max-height: calc(100% - 2 * var(--modal-margin));
    width: fit-content;
    max-width: calc(100% - 2 * var(--modal-margin));
    transition:
      max-height var(--modal-transition-duration) ease 0s,
      max-width var(--modal-transition-duration) ease 0s;

    @media only screen and (max-width: 480px) {
      width: 100%;
    }

    @media only screen and (min-width: 481px) {
      min-width: calc(480px - 2 * var(--modal-margin));
    }

    border-radius: var(--modal-border-radius);
    border: var(--modal-border-width) solid var(--modal-border-color);

    // APPEARENCE
    box-shadow: var(--modal-shadow);

    // CONTENT
    /* Keep modal window from resizing when using plus/minus buttons */
    font-size: 1rem;
    overflow: hidden;
    color: var(--modal-text-color);
    background: var(--modal-background-color);

    // Used to apply shadow
    >.modal-header {
      display: flex;
      align-items: center;
      // SHAPE
      box-sizing: border-box;
      height: var(--modal-header-height);
      padding:
        calc(var(--modal-padding) / 2)
        calc(var(--modal-padding) / 2)
        calc(var(--modal-padding) / 2)
        var(--modal-padding);

      // APPEARANCE
      background: var(--modal-background-color);
      transition: box-shadow var(--modal-transition-duration) ease 0s;
      &.no-shadow {
        box-shadow: none;
      }

      &.show-shadow {
        box-shadow: var(--small-shadow);
      }

      // CONTENT
      >.modal-title {
        position: relative;
        /* h tags, for title, have default browser agent top margin style using to much space on top */
        margin: 0;
        width: 100%;
        min-height: 1rem;
      }

      >.cross-button {
        --button-text-color: var(--modal-text-color);
        --button-background-color: var(--modal-background-color);
        --button-hover-text-color: var(--button-background-color);
        --button-hover-border-color: var(--button-text-color);
        --button-hover-background-color: var(--button-text-color);
      }
    }

    >.modal-content {
      position: relative;
      box-sizing: border-box;
      width: 100%;

      &:not(.no-padding) {
        padding: calc(var(--modal-padding) / 2) var(--modal-padding) var(--modal-padding) var(--modal-padding);
      }

      &.no-padding {
        padding: 0;
      }

      // javascript calculated to get the "real" height using document.body.clientHeight instead of 100vh
      // or using a custom container instaed of body
      // overflow: scroll;
      // max-height: calc(100vh - 2 * var(--modal-padding) - 2 * var(--layout-border-width) -  var(--modal-padding) - 2em);
    }
  }
}

.modal .overflow-bottom {
  z-index: 100;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--modal-background-color);
}