@import '../../styles/screen-sizes.scss';
:root {
  --footer-background-color: var(--color1);
  --footer-y-padding: 1rem;
  --footer-section-padding: .5rem;
  --footer-social-logo-size: 1.2rem;
  --footer-text-color: var(--text-color);
  --footer-link-color: var(--text-color);
  --footer-link-hover-color: var(--link-hover-color);
}

footer {
  // DEFAULT VALUES
  --local-footer-background-color: var(--footer-background-color, var(--color1, #ffffff));
  --local-footer-y-padding: var(--footer-y-padding, 1rem);
  --local-footer-section-padding: var(--footer-section-padding, .5rem);
  --local-footer-social-logo-size: var(--footer-social-logo-size, 1.2rem);

  --local-footer-text-color: var(--footer-text-color, var(--text-color, #565763));
  --local-footer-link-color: var(--footer-link-color, var(--text-color, #565763));
  --local-footer-link-hover-color: var(--footer-link-hover-color);

  --local-footer-transition-duration: var(--element-transition-duration, var(--link-hover-color, var(--primary, rgb(13, 24, 34))));

  // SHAPE
  padding: var(--local-footer-y-padding) 0;

  // APPEARANCE
  color: var(--local-footer-text-color);
  background-color: var(--footer-background-color);

  // CONTENT
  a {
    color: var(--local-footer-link-color);
    transition: color var(--local-footer-transition-duration);
    -webkit-transition: color var(--local-footer-transition-duration);

    &:hover {
      color: var(--local-footer-link-hover-color);
    }
  }

  /* Each row is a section: social-bar, legal-bar  */
  & section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;

    padding: var(--local-footer-section-padding);
  }
}

/* Social logos and icons */
#social-bar {
  a {
    text-decoration: none;
    font-size: calc(var(--local-footer-social-logo-size) * 1.5);
    line-height: calc(var(--local-footer-social-logo-size) * 1.75); /* same as svgs */
    align-self: flex-start;
    font-weight: bolder;
  }

  svg {
    /* 1.75 = ratio svg-picture-size/svg-text-size for the logo to be the same size as the font-size */
    height: calc(var(--local-footer-social-logo-size) * 1.75);
    width: calc(var(--local-footer-social-logo-size) * 100%);

    /****** SVGs with mask layer for hovering transitions ******/
    .mask {
      //fill: var(--local-footer-link-color);
      transition: fill var(--local-footer-transition-duration),
        opacity var(--local-footer-transition-duration);
      -webkit-transition: fill var(--local-footer-transition-duration),
        opacity var(--local-footer-transition-duration);
    }
    &:hover .mask {
      opacity: 0;
    }
  }
}
