#components .section-icons {

  .social-container {
    display: flex;
    gap: 1rem;
  }

  .svg-icons svg g {
    stroke: var(--text-color, #565763);
    fill: none;
  }

  .mask {
    display: none;
  }
}