.periods-bar {
  box-sizing: border-box;
  height: var(--periods-bar-height);

  // CONTENT
  display: flex;
  gap: 1px;
  text-align: center;
  cursor: pointer;

  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background: var(--border-color);
}