.delete-modal {
  >.buttons-bar {
    display: flex;
    justify-content: space-between;
    margin: calc(var(--button-margin) + var(--modal-padding)) 0 var(--button-margin) 0;
  }

  >.buttons-bar>.delete-button {
    --button-text-color: var(--red, red);
  }
}