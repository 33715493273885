/****** RESPONSIVENESS ******/
/*
 * CSS variables dos NOT work inside media query conditions
 * Using SCSS variables instead
 */
 
$phone: 480px;
$phablet: 768px;
$tablet: 992px;
$laptop: 1200px;
$desktop: 1920px;

// Minimum size at which elements will be display (hidden below)
@media screen and (max-width: $phone) {
  .phablet-min {
    display: none !important;
  }
}
@media screen and (max-width: $phablet) {
  .tablet-min {
    display: none !important;
  }
}
@media screen and (max-width: $tablet) {
  .laptop-min {
    display: none !important;
  }
}
@media screen and (max-width: $laptop) {
  .desktop-min {
    display: none !important;
  }
}
@media screen and (max-width: $desktop) {
  .desktophd-min {
    display: none !important;
  }
}

// Maximum size at which elements will be display (hidden above)
@media screen and (min-width: $phone) {
  .phone-max {
    display: none !important;
  }
}
@media screen and (min-width: $phablet) {
  .phablet-max {
    display: none !important;
  }
}
@media screen and (min-width: $tablet) {
  .tablet-max {
    display: none !important;
  }
}
@media screen and (min-width: $laptop) {
  .laptop-max {
    display: none !important;
  }
}
@media screen and (min-width: $desktop) {
  .desktop-max {
    display: none !important;
  }
}