.delete-exercise {
  overflow: hidden;
}

.delete-exercise.visible {
  opacity: 1;
  visibility: visible;
  height: calc(1.125rem * 5 + calc(var(--button-margin) + var(--modal-padding)) + var(--buttton-margin) + var(--button-height));
  transition:
    visibility 0s ease 0s,
    opacity var(--element-transition-duration) ease 0s,
    height var(--element-transition-duration) ease 0s;
}

.delete-exercise.hidden {
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition:
  opacity var(--element-transition-duration) ease 0s,
  height var(--element-transition-duration) ease 0s,
  visibility 0s ease var(--element-transition-duration);
}

.delete-exercise__content {
  height: calc(1.125rem * 5);
}

.delete-exercise__buttons {
  display: flex;
  justify-content: space-between;
  margin: calc(var(--button-margin) + var(--modal-padding)) 0 var(--button-margin) 0;
}

.delete-exercise__buttons .delete-button {
  --button-text-color: var(--red, red);
}