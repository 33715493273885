:root {
  --input-padding: 0.7rem;
  --input-padding-desktop: calc(2 / 3 * var(--input-padding));
  --input-margin: 2rem;
  --input-text-color: var(--text-color, #565763);
  --input-placeholder-color: var(--gray4, rgb(209, 209, 214));
  --input-background-color: var(--content-background-color, #ffffff);
  --input-border-color: var(--gray5, rgb(229, 229, 234));
  --input-border-width: var(--border-width, 1px);
  --input-border-radius: var(--border-radius, 3px);
  --input-shadow: var(--large-medium-shadow, 0 0 4px 4px rgba(0, 0, 0, 2.5%));
  --input-message-size: 0.7em;
  --input-height: calc(1rem + 2 * var(--input-padding));
  --input-transition-duration: var(--element-transition-duration, .3s);
}

.input {
  // Use wrapper with inline-block to prevent margins
  display: inline-block;
  // height: fit-content;
  width: 100%;
}


/****** BORDER ******/
.input:not(.invalid) .input-border {
  &.no-border {
    border: var(--input-border-width) solid transparent;
  }
  &:not(.no-border) {
    border: var(--input-border-width) solid var(--input-border-color);
  }
}

.input.invalid .input-border {
  border: var(--input-border-width) solid red;
}

.input .input-border {
  position: relative;

  // SHAPE
  box-sizing: border-box;
  height: var(--input-height);
  margin: var(--input-margin) 0;

  // APPEARENCE
  &:not(.no-border) {
    box-shadow: var(--input-shadow);
  }

  // CONTENT
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  border-radius: var(--input-border-radius);

  transition:
    border-color var(--element-transition-duration) ease 0s,
    margin-bottom var(--element-transition-duration) ease 0s;
}


/****** LABEL ******/
.input label {
  box-sizing: border-box;
  height: calc(var(--input-height) - 2 * var(--input-border-width));

  border-bottom-left-radius: var(--input-border-radius);
  border-top-left-radius: var(--input-border-radius);

  padding: var(--input-padding);
  color: var(--input-text-color);
  
  // align span vertivally
  display: flex;
  align-items: center;
}

// Use span to center label verticallly
.input.required label span::after {
  content: " ✶";
  font-size: 0.75em;
  font-weight: 100;
  vertical-align: text-top;
  color: var(--red, red);
  transition: color var(--input-transition-duration) ease 0s;
}

.input.required.filled label span::after {
  color: inherit
}


/****** INPUT ******/
.input .input-container {
    /* To position children if any (ex: dropdown-list) */
    position: relative;
    align-items: center;
    box-sizing: border-box;
    height: calc(var(--input-height) - 2 * var(--input-border-width));

    display: flex;
    justify-content: flex-end;

  input {
    // SHAPE
    box-sizing: border-box;
    height: calc(var(--input-height) - 2 * var(--input-border-width));

    // CONTENT
    /* Allow resizing with the rest of the content */
    font-size: 1em;
    text-align: right;
    // APPEARANCE
    text-overflow: ellipsis;
    border: none;
    background-color: transparent;
    text-decoration: none;

    transition:
      color var(--input-transition-duration) ease 0s,
      background-color var(--input-transition-duration) ease 0s,
      border-color var(--input-transition-duration) ease 0s;

    &:focus {
      outline: none;
    }

    border-top-right-radius: calc(var(--input-border-radius) - var(--input-border-width));
    border-bottom-right-radius: calc(var(--input-border-radius) - var(--input-border-width));

    // INPUT TYPES
    &[type=text],
    &[type=email],
    &[type=password] {
      padding: var(--input-padding);

      // APPEARANCE
      color: var(--input-text-color);

      &:focus-within {
        color: var(--input-background-color);
        background-color: var(--input-text-color);
      }

      &::placeholder {
        color: var(--input-placeholder-color);
      }
    }

    &[type=file] {
      // POSITION
      margin-left: var(--input-padding);
      
      // CONTENT
      font-family: var(--font-name);
      
      // Hide default button which cannot be customized to create a new one with ::before
      &::-webkit-file-upload-button {
        display: none;
      }
      
      color: transparent; // Hide default filename
      display: flex;
      height: 1.3rem;
      &::before {
        // CONTENT
        content: "choose file";
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font: var(--font-name);
        text-align: center;
        // APPREARANCE
        color: var(--input-text-color);
        border: var(--input-border-width) solid var(--input-border-color);
        border-radius: var(--input-border-radius);
        background-color: var(--input-background-color);
      }

      &:hover {
        cursor: pointer;

        &::before {
          color: var(--input-background-color);
          border-color: var(--input-background-color);
          background-color: var(--input-text-color);
        }
      }

      &:active {
        &::before {
          border-color: var(--input-text-color);
        }
      }
    }

    &[type=button] {
      // SHAPE
      padding: 1px 0.5em 2px 0.5em; // recreate the padding of the standard "choose file" button
      margin: var(--input-padding) 0;
      border: var(--input-border-width) solid var(--input-border-color);
      border-radius: calc(var(--input-border-radius) - var(--input-border-width));

      // APPEARANCE
      color: var(--input-text-color);
      background-color: var(--input-background-color);
      cursor: pointer;

      // CONTENT
      font-weight: lighter;

      &:hover {
        color: var(--input-background-color);
        border-color: var(--input-text-color);
        background-color: var(--input-text-color);
      }

      &:active {
        border-color: var(--input-background-color);
      }

      &.hidden {
        visibility: hidden;
      }
    }

    &[type=checkbox] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      background-color: var(--input-background-color);
      margin: var(--input-padding);
      cursor: pointer;

      font: inherit;
      color: var(--input-text-color);
      width: 1.15em;
      height: 1.15em;
      border: var(--input-border-width) solid var(--input-border-color);
      border-radius: var(--input-border-radius);
      transform: translateY(-0.075em);

      display: grid;
      place-content: center;

      /* Checkmark */
      &::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        background-color: var(--button-text-color);

        /* Checkmark form */
        transform-origin: center;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      }

      &:checked::before {
        transform: scale(1);
      }

      &:disabled {
        color: var(--color3);
        border-color: var(--color3);
        cursor: not-allowed;

        &::before {
          box-shadow: inset 1em 1em var(--color3);
        }
      }
    }

    &[type=color] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      opacity: 0;
      height: var(--input-color-size);
      width: var(--input-color-size);
      border-radius: var(--input-color-size);
      padding: 0;
      position: absolute;
      top: calc(-1 * var(--input-border-width));
      left: calc(-1 * var(--input-border-width));

      &::-webkit-color-swatch-wrapper {
        padding: 0;
      }

      &::-webkit-color-swatch {
        border: none;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
  
  --input-color-size: 1.4rem;
  .color-picker-wrapper {
    position: relative;
    box-sizing: border-box;
    min-height: var(--input-color-size);
    max-height: var(--input-color-size);
    min-width: var(--input-color-size);
    max-width: var(--input-color-size);

    border: var(--input-border-width) solid var(--input-border-color);
    border-radius: var(--input-color-size)var(--input-color-size);

    margin: 0 calc((var(--input-height) - 2 * var(--input-border-width) - var(--input-color-size)) / 2);

    transition:
      border-color var(--input-transition-duration) ease 0s,
      background-color var(--input-transition-duration) ease 0s;

    &:hover,
    &:focus-within {
      cursor: pointer;
      border-color: var(--button-text-color);
      background-color: var(--button-background-color);
    }
  }
}

.input .right-button {
  margin: 0 var(--input-padding);
  --button-height: 1.46rem;
  --button-text-color: var(--input-text-color);
  --button-hover-background-color: var(--input-text-color);
  --button-hover-border-color: var(--input-text-color);
  --button-active-text-color: var(--input-background-color);
  --button-active-background-color: var(--input-text-color);
}

.input-password .input-container input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input ::selection {
  background-color: var(--input-background-color);
  color: var(--input-text-color);
}

.input.inverse-colors {
  ::selection {
    background-color: var(--input-text-color);
    color: var(--input-background-color);
  }

  label {
    color: var(--input-background-color);
  }

  input {

    &[type=text],
    &[type=email],
    &[type=color],
    &[type=password] {
      color: var(--input-background-color);

      &:focus-within {
        color: var(--input-text-color);
        background-color: var(--input-background-color);

        &::placeholder {
          color: var(--input-text-color);
          background-color: var(--input-background-color);
        }
      }
    }
  }
}


/****** MESSAGE ******/
.input .input-message {
  position: absolute;
  bottom: calc(-2.2 * var(--input-message-size));
  right: 0;
  z-index: 9;

  height: calc(1.5 * var(--input-message-size));

  font-size: var(--input-message-size);

  margin-right: var(--input-border-radius);
  margin-left: auto;
  transition:
    height var(--element-transition-duration) ease 0s,
    opacity var(--element-transition-duration) ease 0s;
}

.input .input-message.visible {
  opacity: 1;
}

.input .input-message.hidden {
  opacity: 0;
}


/* Animate input to enlarge and label to slide */
.input.animate {
  position: relative;

  label {
    transition: var(--element-transition-duration);
    z-index: 1;
    width: auto;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .input-container {
    transition: var(--element-transition-duration);
    z-index: 0;
    position: absolute;
    right: 0;
    width: 50%;

    >input:not([type=color]):not([type=checkbox]) {
      width: 100%;
    }
  }


  @mixin active-input {
    label {
      transition: var(--element-transition-duration);
      color: var(--input-text-color);
      font-size: 1em;
      bottom: calc(1em + var(--input-padding) + 0.3em);

      &::after {
        font-size: 0.5em;
      }
    }

    .input-container {
      transition: var(--element-transition-duration);
      -webkit-transition: var(--element-transition-duration);
      width: 100%;

      >input:not([type=color]):not([type=checkbox]) {
        transition: var(--element-transition-duration);
        width: 100%;
        border-top-left-radius: calc(var(--input-border-radius) - var(--input-border-width));
        border-bottom-left-radius: calc(var(--input-border-radius) - var(--input-border-width));
      }
    }
  }

  &:focus-within:not(.input-file):not(.input-checkbox),
  &.filled:not(.input-file):not(.input-checkbox) {
    @include active-input;
  }

  @media screen and (max-width: 560px) {
    &:not(.input-checkbox) {
      @include active-input;
    }
  }
}

.input.animate.inverse-colors:focus-within label {
  color: var(--input-text-color);
}