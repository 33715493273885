#profile-form {
  --local-input-min-width: 200px;
  --local-input-min-width-desktop: 300px;
  --local-input-max-width: 400px;
  --local-column-gap: var(--modal-padding);

  .title {
    text-align: center;
    margin-bottom: 3rem;
  }

  .form-inputs {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 800px) {
      flex-direction: column;
    }

    @media screen and (min-width: 801px) {
      flex-direction: row;
      --local-input-min-width: var(--local-input-min-width-desktop);
    }

    .input-container {
      height: calc(var(--input-height) + 2 * var(--input-margin));
      overflow: hidden;
      opacity: 1;
      visibility: visible;
      transition:
        height var(--element-transition-duration) ease 0s,
        opacity var(--element-transition-duration) ease 0s,
        visibility 0s ease 0s;
    }

    .input-container.hidden {
      height: 0;
      opacity: 0;
      visibility: hidden;
      transition:
        height var(--element-transition-duration) ease 0s,
        opacity var(--element-transition-duration) ease 0s,
        visibility 0s ease var(--element-transition-duration);
    }

    .user-info-column {
      width: 100%;
      min-width: var(--local-input-min-width);
      
      .change-password {
        overflow: hidden;
        transition: height var(--element-transition-duration, .2s) ease 0s;
        
        &:not([open]) {
          height: calc(1.125rem + 2 * var(--input-margin));
        }
        &[open] {
          height: calc(1.125rem + 3 * var(--input-margin) + 3 * (var(--input-height) + 2 * var(--input-margin)));
        }
        &.hidden {
          height: 0;
        }

        summary {
          cursor: pointer;
          user-select: none;
          -webkit-user-select: none;
          padding: var(--input-margin) 0;
        }
        
      }
    }


    .profile-picture-column {
      max-height: calc(var(--input-height) + 2 * var(--input-margin) + var(--local-input-max-width));
      width: 100%;
      min-width: var(--local-input-min-width);
      max-width: var(--local-input-max-width);
      overflow: hidden;
      opacity: 1;
      visibility: visible;

      @media screen and (max-width: 800px) {
        transition:
          max-height var(--element-transition-duration) ease 0s,
          opacity var(--element-transition-duration) ease 0s,
          visibility 0s ease 0s;
      }

      @media screen and (min-width: 801px) {
        margin-left: var(--modal-padding);
        transition:
          margin-left var(--element-transition-duration) ease 0s,
          max-height var(--element-transition-duration) ease 0s,
          min-width var(--element-transition-duration) ease 0s,
          max-width var(--element-transition-duration) ease 0s,
          opacity var(--element-transition-duration) ease 0s,
          visibility 0s ease 0s;
      }
    }

    .profile-picture-column.hidden {
      max-height: 0;
      opacity: 0;
      visibility: hidden;

      @media screen and (max-width: 800px) {
        transition:
          max-height var(--element-transition-duration) ease 0s,
          opacity var(--element-transition-duration) ease 0s,
          visibility 0s ease var(--element-transition-duration);
      }

      @media screen and (min-width: 801px) {
        min-width: 0;
        max-width: 0;
        margin-left: 0;
        transition:
          margin-left var(--element-transition-duration) ease 0s,
          max-height var(--element-transition-duration) ease 0s,
          min-width var(--element-transition-duration) ease 0s,
          max-width var(--element-transition-duration) ease 0s,
          opacity var(--element-transition-duration) ease 0s,
          visibility 0s ease var(--element-transition-duration);
      }
    }

    .image-preview {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      border-radius: var(--border-radius);

      &:before {
        content: "";
        display: block;
        padding-top: 100%; // padding-top is calculated based on width, so it makes a square
      }
    }

    .image-preview>img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      object-fit: cover;
    }

    .image-preview>svg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);

      g {
        stroke: var(--border-color);
      }
    }

    .image-preview {
      border: var(--border-width) solid var(--border-color);
    }
  }

  .reset-message-container {
    opacity: 0;
    height: 1rem;
    transition: 
      opacity var(--element-transition-duration) ease 0s,
      height var(--element-transition-duration) ease 0s;
  }
  .reset-message-container.visible {
    opacity: 1;
    height: 1rem;
  }
  .reset-message-container.hidden {
    opacity: 0;
    height: 0;
  }

  .reset-pin-container {
    overflow: hidden;
    margin-block-start: 2rem;
    opacity: 1;
    height: var(--pin-height);
    transition: 
      margin-block-start var(--element-transition-duration) ease 0s,
      opacity var(--element-transition-duration) ease 0s,
      height var(--element-transition-duration) ease 0s;
  }
  .reset-pin-container.hidden {
    margin-block-start: 0;
    opacity: 0;
    height: 0;
  }

  .reset-pin-error-message {
    text-align: center;
    margin-top: 0;
    opacity: 0;
    height: 0;
    color: var(--red);
    transition: 
      opacity var(--element-transition-duration) ease 0s,
      height var(--element-transition-duration) ease 0s,
      margin-top var(--element-transition-duration) ease 0s;
  }
  .reset-pin-error-message.visible {
    opacity: 1;
    height: 1rem;
    margin-top: 1rem;
  }

  .reset-link-container {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-inline-start: var(--border-radius);
    margin-block-start: calc(-2 * var(--input-margin) / 3);
    margin-bottom: 0;

    opacity: 1;
    height: 1.12rem;
    transition:
      opacity var(--element-transition-duration) ease 0s,
      height var(--element-transition-duration) ease 0s,
      margin-block-start var(--element-transition-duration) ease 0s;
  }
  .reset-link-container.hidden {
    opacity: 0;
    height: 0;
    margin-block-start: 0;
  }

  .reset-link {
    font-size: 0.8rem;
    color: var(--gray3);

  }

  .logout-link,
  .create-link,
  .login-link {
    display: flex;
    align-items: center;
    height: var(--button-height, 2rem);
    width: fit-content;
    color: var(--red, red);
    margin-top: calc(var(--input-margin) + var(--modal-padding));
    margin-bottom: calc(-1 * var(--button-height)); // To align the link with the button
  }

  .create-button,
  .login-button,
  .reset-button {
    bottom: 1rem;
    margin-left: auto;
    margin-bottom: var(--modal-padding);
  }

  .save-button {
    position: sticky;
    bottom: 0;
    margin-left: auto;
  }
}