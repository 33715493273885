#sport {
  --date-line-height: 0.5rem;
  --date-font-size: 0.7rem;
  --periods-bar-height: var(--button-height);

  .toolbar {
    --toolbar-button-background-color: var(--color2);
    --toolbar-background-color: var(--content-background-color);
  }

  user-select: none;
  height: 100%;

  .section {
    // POSITION
    position: relative;

    // CONTENT
    align-items: center;
    gap: 0.5rem;

    // SHAPE
    height: 100%;
    padding-bottom: 0;
  }

  --bars-margin: 1rem;
  .periods-bar {
    margin: var(--bars-margin)  0;
    @media screen and (min-width: 481px) {
      width: max-content;
      margin-left: auto;
      width: 400px;
    }
  }
}

.sport-start-button {
  --start-button-size: 4rem;
  position: absolute;
  bottom: max(calc((var(--navbar-height) - var(--start-button-size)) / 2), 0.5rem);
  left: 50%;
  transform: translate(-50%);
  z-index: 300;

  height: var(--start-button-size);
  width: var(--start-button-size);
  border-radius: var(--start-button-size);
  box-shadow: var(--large-dark-shadow);

  /*
  --button-text-color: var(--content-background-color);
  --button-background-color: var(--blue);
  */
}