:root {
  --box-text-color: var(--text-color, #565763);
  --box-background-color: var(--layout-background-color, #fff);
  --box-background-color--rgb: var(--layout-background-color--rgb, #fff);
  --box-padding: 1rem;
  /* positionning the box next to the button with margin value */
  --box-gap-with-button: 3px;
  --box-gap-with-container: 1rem;
}

.box-button-container {
  position: relative;

  >.box-button {
    /* For box and triangle absolute positions to be relative to the button */
    position: relative;

    // SHAPE
    width: 100%;
    max-width: 100%;
    height: var(--button-height, 2em);

    // APPEARENCE
    &.plain {
      background-color: var(--button-background-color);
    }

    // CONTENT
    /* Center triangle */
    display: flex;
    align-items: center;

    >.box-button-label {
      display: flex;
      align-content: center;
    }
  }


  /** TRIANGLE */
  >.box-button.triangle::after {
    content: "";
    /* Make a 8px transparent square only made of borders (4px height) */
    border: 0.25em solid transparent;
    /* Change top border color to reveal the triangle */
    border-top-color: var(--button-text-color);
    /* Borders join to the center */
    width: 0;
    height: 0;

    /* Base = 2 * Height = 8px */
    /* Push triangle to the bottom to center it */
    margin-top: 0.25em;
    /* Push triangle to the right to create space with left content */
    margin-left: 0.5em;

    transition: border-top-color var(--element-transition-duration) ease 0s;
  }
  >.box-button.triangle.inverse-colors::after {
    border-top-color: var(--button-background-color);
  }

  @media (hover: hover) {
    >.box-button.triangle:hover::after {
      border-top-color: var(--button-background-color);
    }
    >.box-button.triangle.inverse-colors:hover::after {
      border-top-color: var(--button-text-color);
    }
  }

  >.box-button.triangle.active::after {
    border-top-color: var(--button-background-color);
  }
  >.box-button.triangle.inverse-colors:active::after,
  >.box-button.triangle.inverse-colors.active::after {
    border-top-color: var(--button-background-color);
  }

  >.box-button.box-button.triangle:disabled::after {
    border-top-color: var(--gray-light);
  }

  /** BOX **/
  >.box {
    /* POSITION */
    position: absolute;
    /* !!! 
      max-height, max-width, top, right, bottom, left are Javascript calculated
    */

    /* SHAPE */
    border-radius: var(--border-radius);
    margin: 0;

    /* APPEARANCE */
    cursor: auto;
    border: var(--layout-border-width) solid var(--border-color);

    color: var(--box-text-color);
    background: rgba(var(--box-background-color--rgb), 0.8);
    backdrop-filter: saturate(180%) blur(10px);
    box-shadow: var(--large-medium-shadow);

    /* Box default visibility */
    visibility: hidden;
    opacity: 0;
    transition:
      opacity var(--element-transition-duration) ease 0s,
      z-index 0s ease var(--element-transition-duration),
      visibility 0s ease var(--element-transition-duration);

    >.box-content {
      /* CONTENT */
      text-align: left;
      box-sizing: border-box;
      padding: var(--box-padding);

      h1:first-child,
      h2:first-child,
      h3:first-child,
      h4:first-child,
      h5:first-child,
      h6:first-child {
        margin-top: 0;
      }
    }
  }

  >.box.opened {
    z-index: 9999;
    visibility: visible;
    opacity: 1;
    transition:
      visibility 0s ease 0s,
      z-index 0s ease 0s,
      opacity var(--element-transition-duration) ease 0s;
  }
}