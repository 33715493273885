.timer {
  display: flex;
  justify-content: center;

  .digits {
    width: 1ch;
    text-align: center;
  }
  .separator {
    text-align: center;
  }
}