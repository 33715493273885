.history-list {
  /* Javascript calculated
   height: calc(
    100%
    - 200px
    - var(--date-line-height)
    - var(--date-font-size)
    - 2 * var(--bars-margin)
    - var(--periods-bar-height)
  );
  */
  transition: height var(--element-transition-duration) ease 0s;

  --edit-column-width: 2rem;
  .date-column {
    text-align: left;
    width: calc(20% - var(--edit-column-width));
  }

  .sets-column {
    text-align: center;
    width: calc(60% - var(--edit-column-width));
  }

  .total-column {
    text-align: right;
    width: calc(20% - var(--edit-column-width));
  }

  .edit-column {
    text-align: right;
    width: var(--edit-column-width);
  }

  .edit-button {
    --button-height: calc(var(--edit-column-width) * 0.8);
    --button-text-color: var(--text-color);
  }

  .edit-icon {
    height: calc(var(--edit-column-width) * 0.5);
    width: calc(var(--edit-column-width) * 0.5);
  }

  --history-list-header-height: 2rem;
  position: relative;
  .header {
    /* POSITION */
    position: sticky;
    top: 0;

    /* CONTENT */
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* SHAPE */
    height: 2rem;

    /* APPEARANCE */
    background: var(--content-background-color);
    border-bottom: var(--border-width) solid var(--border-color);
  }

  .list-container {
    height: calc(100% - var(--history-list-header-height));
    overflow: scroll;
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 2rem;
  }

  .item:not(:last-of-type) {
    border-bottom: var(--border-width) solid var(--border-color);
  }

  .sets {
    display: flex;

    position: relative;
    /* Position is javascript calculated
    .day {
      {
        position: 'absolute',
        top: '50%',
        left: `${i * (100 / (sets.length - 1))}%`,
        transform: `translate(${-i * (100 / (sets.length - 1))}%, -50%)`,
      }
    }
    */
  }

  .no-data-message {
    height: 2rem;

    display: grid;
    place-items: center;
  }
}