.cancel-workout-modal {

  .buttons-bar  {
    display: flex;
    justify-content: space-between;

    padding-top: var(--modal-padding);
  }

  .buttons-bar .cancel-button {
    --button-text-color: var(--red, red);
  }
}