#welcome {
  height: 100%;
  .section {
    // CONTENT
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    .animation {
      animation-duration: 4s;
      -webkit-animation-duration: 4s;
      animation-timing-function: ease-out;
      -webkit-animation-timing-function: ease-out;
      animation-iteration-count: 1;
      -webkit-animation-iteration-count: 1;
    }
    .fadeIn1{
      // Using "animation" instead of "transition" for onload effect
      animation-name: fadeIn1;
      -webkit-animation-name: fadeIn1;
    }
    .fadeIn2 {
      animation-name: fadeIn2;
      -webkit-animation-name: fadeIn2;
    }
    .fadeIn3 {
      animation-name: fadeIn3;
      -webkit-animation-name: fadeIn3;
    }
    .fadeIn4 {
      animation-name: fadeIn4;
      -webkit-animation-name: fadeIn4;
    }
    .fadeIn5 {
      animation-name: fadeIn5;
      -webkit-animation-name: fadeIn5;
    }

    @keyframes fadeIn1 {
      0% {
        opacity: 0;
      }
      20% {
        opactity: 1;
      }
      100% {
        opactity: 1;
      }
    }
    @keyframes fadeIn2 {
      0% {
        opacity: 0;
      }
      20% {
        opacity: 0;
      }
      40% {
        opacity: 1;
      }
      100% {
        opactity: 1;
      }
    }
    @keyframes fadeIn3 {
      0% {
        opacity: 0;
      }
      40% {
        opacity: 0;
      }
      60% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes fadeIn4 {
      0% {
        opacity: 0;
      }
      60% {
        opacity: 0;
      }
      80% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes fadeIn5 {
      0% {
        opacity: 0;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
