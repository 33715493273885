#theme {
  .theme-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;

    > .theme-category {
      /* SHAPE */
      width: 20em;
      max-width: 50em;
      flex-grow: 1;
      height: fit-content;
      border-radius: var(--border-radius);

      .theme-category-title {
        text-align: center;
        padding-bottom: 1rem;
      }
    }

    /* Deactivate Colors from picture feature
    > .colors {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  
    > .colors > img {
      height: 12rem;
      margin: 0.2rem;
      border: var(--border-width) solid var(--text-color);
      border-radius: var(--border-radius);
    }
    */
  }

}
