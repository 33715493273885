#components {
  .flex-container {
    // CONTENT
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    &.column-reverse {
      flex-direction: column-reverse;
    }
    &.column {
      flex-direction: column;
    }
    &.row-reverse {
      flex-direction: row-reverse;
    }

    margin-bottom: 1rem;
  }
}
