
.tooltip {
  --triangle-size: 12px;
  --triangle-semi-diagonal: calc((var(--triangle-size) * 1.4142 / 2));
  --triangle-gap: 3px;
  --tooltip-on-the-right: calc(50% - 2 * var(--border-radius) - var(--triangle-semi-diagonal));
  --tooltip-centered: -50%;

  // POSITION
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9999;

  // APEARANCE
  background: var(--layout-background-color);
  box-shadow: var(--large-medium-shadow);
  transition-duration: var(--element-transition-duration);

  // CONTENT
  font-size: 1em;

  // SHAPE
  width: max-content;
  border-radius: var(--border-radius);
  border: var(--border-width) solid var(--border-color);
  padding: 0.5rem;
  &::before {
    content: "";
    position: absolute;
    z-index: 9999;
  
    width: var(--triangle-size);
    height: var(--triangle-size);
    transform: rotate(45deg);
  
    background-color: white;
    border-bottom: var(--border-width) solid var(--border-color);
    border-right: var(--border-width) solid var(--border-color);
  }
}

.tooltip.top {
  bottom: calc(100% + var(--triangle-semi-diagonal) + var(--border-width) + var(--triangle-gap));
  left: var(--tooltip-on-the-right);
  &::before {
    bottom: calc(-1 * var(--triangle-semi-diagonal) + var(--border-width));
    left: calc(2 * var(--border-radius));
  }
}

.tooltip.bottom {
  top: calc(100% + var(--triangle-semi-diagonal) + var(--border-width) + var(--triangle-gap));
  left: var(--tooltip-on-the-right);
  &::before {
    top: calc(-1 * var(--triangle-semi-diagonal) + 2 * var(--border-width));
    left: calc(2 * var(--border-radius));
    transform: rotate(-135deg);
  }
}

.tooltip.center {
  left: 50%;
  transform: translateX(-50%);
  &::before {
    left: calc(50% - var(--triangle-semi-diagonal));
  }
}

.tooltip.hidden {
  visibility: hidden;
  opacity: 0;
}
.tooltip:hover,
.tooltip.visible {
  visibility: visible;
  opacity: 1;
}
